
import React, { useState,Fragment } from 'react';
import NavbarComponent from '../Components/Navbar';
import FooterComponent from '../Components/Footer';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import {
  ScrollingProvider,
  useScrollSection,
  Section,
} from 'react-scroll-section';
import cote from './../Assets/cote.svg';
import watch from './../Assets/watch.svg';
import credibilidade from './../Assets/SobreNos_Credibilidade 1.svg';
import historia from './../Assets/SobreNos_Historia 1.svg';
import sustentabilidade from './../Assets/SobreNos_Sustentabilidade 1.svg';

import DOMPurify from 'dompurify'
import i18n from 'i18next';
i18n.changeLanguage(localStorage.getItem("language"));
export default class LivroReclamacao extends React.Component {


  
  scrollToTestDiv = value => {
    const divElement = document.getElementById('manifesto');
    divElement.scrollIntoView({ behavior: 'smooth' });
  }


  render() {


    return ( <> { isMobile && window.innerWidth < 720 ? <>
      <Container  className="navbar_fixed">
        <NavbarComponent page={"LivroReclamacao"}></NavbarComponent>
      </Container>
      <Container style={{paddingRight:"16px",paddingLeft:"16px",marginTop:"120px"}}>
        <Row className="justify-content-md-center">
        <Col xs lg="10">     
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="10">
                <div className="IMB_Title">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_reclamacao_1"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="10">
                <div className="WORK_Description">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_reclamacao_2"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center  mb-24" >
              <Col xs lg="10">
                <div className="IMB_Title_20">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_reclamacao_3"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="10">
                <div className="WORK_Description">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_reclamacao_4"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="10">
                <div className="IMB_Title_20">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_reclamacao_5"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="10">
                <div className="WORK_Description">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_reclamacao_6"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-80" >
              <Col xs lg="10">
                <div className="WORK_Description">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_reclamacao_7"))}}></div></p>
             </div>
              </Col>
            </Row>
             <Row className="justify-content-md-center mb-80">
                <Col xs lg="10" className="textAligncenter" >

                <a href="#"  ><img src="/Assets/btn_up.svg"  style={{width:"50px"}}></img></a>
                </Col>
              </Row>

          </Col>
        </Row>
      </Container>
      <Container className="mt-3">
        <FooterComponent></FooterComponent>

      </Container>
    </> : <>
      {/* DESKTOP */}
      <Container className="mt-3">
        <NavbarComponent page={"LivroReclamacao"}></NavbarComponent>

      </Container>
      <Container>

        <Row className="justify-content-md-center">
        <Col xs lg="10">     
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="10">
                <div className="IMB_Title">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_reclamacao_1"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="10">
                <div className="WORK_Description">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_reclamacao_2"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center  mb-24" >
              <Col xs lg="10">
                <div className="IMB_Title_20">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_reclamacao_3"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="10">
                <div className="WORK_Description">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_reclamacao_4"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="10">
                <div className="IMB_Title_20">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_reclamacao_5"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="10">
                <div className="WORK_Description">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_reclamacao_6"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-121" >
              <Col xs lg="10">
                <div className="WORK_Description">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_reclamacao_7"))}}></div></p>
             </div>
              </Col>
            </Row>
            

          </Col>
        </Row>
      </Container>
      <div className="FooterAll">
        <FooterComponent></FooterComponent>

      </div>
    </>}

    </>
    );
  }
}


