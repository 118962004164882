
import React from 'react';
import NavbarComponent from '../Components/Navbar';
import FooterComponent from '../Components/Footer';
import { Row, Col, Container } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';



import { TRANSLATIONS_PT } from './../translations/pt/translations';
import { TRANSLATIONS_DE } from './../translations/de/translations';
import { TRANSLATIONS_EN } from './../translations/en/translations';
import { TRANSLATIONS_FR } from './../translations/fr/translations';
import DOMPurify from 'dompurify';
import i18n from 'i18next';
import { useParams } from "react-router-dom";
i18n.changeLanguage(localStorage.getItem("language"));
export default class Search extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      lista_textos:[],
    }
  }
  
  

   async componentDidMount() {

    var texto = TRANSLATIONS_PT;

    switch (localStorage.getItem("language")) {
      case "pt":
        texto = TRANSLATIONS_PT;
        break;
      case "en":
        texto = TRANSLATIONS_EN;
        break;
      case "de":
        texto = TRANSLATIONS_DE;
        break;
      case "fr":
        texto = TRANSLATIONS_FR;
        break;

    }

    var path = window.location.pathname;
    var parts = path.split('/');
    var lastSegment = parts.pop() || parts.pop();
    lastSegment= lastSegment.replace('%20',' ');
    console.log(lastSegment);
    console.log(texto);


    var lista_textos = [];


    for (var world in texto) {


      if (texto[world].toUpperCase().indexOf(lastSegment.toUpperCase()) != -1) {
        lista_textos.push(texto[world]);
      }
    }


   await this.setState({ lista_textos: lista_textos });

console.log(this.state.lista_textos);
document.body.innerHTML = document.body.innerHTML.split(lastSegment).join("<font style=\"color:#004225;font-family: 'Work Sans Medium';\">"+lastSegment+"</font>");

  }

  

  render() {


    return (<> {isMobile && window.innerWidth < 720 ? <>
      <Container className="navbar_fixed">
        <NavbarComponent page={"Search"}></NavbarComponent>
      </Container>
      <Container style={{ paddingRight: "16px", paddingLeft: "16px", marginTop: "120px" }}>
      <Row className="justify-content-md-center">
          <Col xs lg="10">
          <Row className="justify-content-md-center mb-24" ><Col xs lg="10">
                  <div className="IMB_Title">
                    <p> <span dangerouslySetInnerHTML={{ __html: (i18n.t("titulo_pesquisa")) }}></span></p>
                  </div>
                </Col>
              </Row>
            {this.state.lista_textos.map((item,key) => {
              return  <Row className="justify-content-md-center mb-24" key={key}><Col xs lg="10">
                  <div className="WORK_Description">
                   <span className="linha_pesquisa_span" ><hr className="linha_pesquisa" /></span> <span dangerouslySetInnerHTML={{ __html: (i18n.t(item)) }}></span>
                  </div>
                </Col>
              </Row>
            })}
          </Col>
        </Row>
      </Container>
      <Container className="mt-3">
        <FooterComponent></FooterComponent>

      </Container>
    </> : <>
      {/* DESKTOP */}
      <Container className="mt-3">
        <NavbarComponent page={"Search"}></NavbarComponent>

      </Container>
      <Container>

        <Row className="justify-content-md-center">
          <Col xs lg="10">
          <Row className="justify-content-md-center mb-24" ><Col xs lg="10">
                  <div className="IMB_Title">
                    <p> <span dangerouslySetInnerHTML={{ __html: (i18n.t("titulo_pesquisa")) }}></span></p>
                  </div>
                </Col>
              </Row>
            {this.state.lista_textos.map((item,key) => {
              return  <Row className="justify-content-md-center mb-24" key={key}><Col xs lg="10">
                  <div className="WORK_Description">
                   <span className="linha_pesquisa_span" ><hr className="linha_pesquisa" /></span> <span dangerouslySetInnerHTML={{ __html: (i18n.t(item)) }}></span>
                  </div>
                </Col>
              </Row>
            })}
          </Col>
        </Row>
      </Container>
      <div className="FooterAll">
        <FooterComponent></FooterComponent>

      </div>
    </>}

    </>
    );
  }
}


