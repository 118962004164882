import React, { useEffect } from 'react';
import { Row, Col, Container, Form, InputGroup, FormControl, Nav, Navbar, Button } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import search from './../Assets/search.svg';


import i18n from 'i18next';
import { useTranslation } from "react-i18next";
import "./../translations/i18n";


let count;





function NavbarComponent(props) {

  const { page } = props;
  const { t } = useTranslation();

  const minDefaut = 50;
  const HourDefault = 13;

  const handleEvent = event => {
    alert("I was clicked");
  };


  const ChangeL = async (language) => {
    console.log(language);
    i18n.changeLanguage(language);

    await localStorage.setItem("language", language);
    await localStorage.setItem("i18nextLng", language);
    window.location.reload();
  };


  const searchClick = async () => {
    console.log("aqui");
    var filtro = document.getElementById("inlineFormInputGroup").value;
    window.location.href = "/search/" + filtro;

  }






  useEffect(async () => {


    var language = await localStorage.getItem("language");

    if (language != i18n.language && language != undefined && language != "") {
      i18n.changeLanguage(language);
    }

    const minEl = document.querySelector(".min");
    const hourEl = document.querySelector(".hour");

    const minDeg = (minDefaut / 60) * 360 - 90;
    const hourDeg = (HourDefault / 12) * 360 - 90;
    minEl.style.transform = `rotate(${minDeg}deg)`;
    hourEl.style.transform = `rotate(${hourDeg}deg)`;
    // console.log(date.getSeconds())
    // console.log(date.getMinutes())
    //   }, 10);

    var WaitListClock = document.getElementById("clockLink");


    // this handler will be executed only once when the cursor moves over the unordered list
    WaitListClock.addEventListener("mouseover", function (event) {


      const minEl = document.querySelector(".min");
      const hourEl = document.querySelector(".hour");

      var min = minDefaut;
      var hour = HourDefault;
      count = setInterval(() => {
        min = min + 1;
        if (min == 60) {
          min = 1;
          hour = hour + 1;
        }
        if (hour == 24) {

          hour = 0;
        }

        const minDeg = (min / 60) * 360 - 90;
        const hourDeg = (hour / 12) * 360 - 90;
        minEl.style.transform = `rotate(${minDeg}deg)`;
        hourEl.style.transform = `rotate(${hourDeg}deg)`;
      }, 10);

    }, false);


    WaitListClock.addEventListener("mouseout", function (event) {

      clearInterval(count)
      const minEl = document.querySelector(".min");
      const hourEl = document.querySelector(".hour");

      const minDeg = (minDefaut / 60) * 360 - 90;
      const hourDeg = (HourDefault / 12) * 360 - 90;
      minEl.style.transform = `rotate(${minDeg}deg)`;
      hourEl.style.transform = `rotate(${hourDeg}deg)`;

    }, false);

  });



  return (
    <> {isMobile && window.innerWidth < 720 ? <>

      <Navbar bg="light" expand="lg">
        <Container style={{ padding: "0px" }}>
          <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ padding: "0px" }} />
          <Navbar.Brand href="/" className="logo_mobile  mx-auto" ><span>Clock(</span><span className="logo_mobile_re">Re</span><span>)Cycle</span></Navbar.Brand>
          <Navbar.Brand href="/waitlist" style={{ marginRight: "2px" }} id='clockLink'>
            <Row style={{ height: "50px" }}>
              <Col xs lg={{ span: 2, offset: 10 }} className="textAligncenter">
                <div className="clock">
                  <div className="hour"></div>
                  <div className="min"></div>

                </div>


                <p className="whaitlist_navbar_text">{t("menu_waitlist")}</p>
              </Col>
            </Row>
          </Navbar.Brand>

          <Navbar.Collapse style={{ height: "100vh" }}>
            <Nav className="me-auto">
              <Nav.Link href="/manifest">{t("menu_manifesto")}</Nav.Link>
              <Nav.Link href="/about">{t("menu_sobre_nos")}</Nav.Link>
              <Nav.Link href="/waitlist">{t("menu_waitlist")}</Nav.Link>
              <hr />
              <Nav.Link href="/contact">{t("menu_contactos")}</Nav.Link>
              <Form.Select className="select-language" defaultValue={"pt"} value={i18n.language} onChange={language => ChangeL(language.target.value)} >
                <option value="pt">{t("pt")}</option>
                <option value="en">{t("en")}</option>
                <option value="fr">{t("fr")}</option>
                <option value="de">{t("de")}</option>
              </Form.Select>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container style={{ padding: "0px" }}>
        <Row>
          <Col xs lg="7">
            <InputGroup className="mb-2">
              <InputGroup.Text style={{ background: "rgba(196, 196, 196, 0.5)", border: "0px", borderRadius: "0px " }} ><Button onClick={value => searchClick()} className='btn_warning btn_mobile_w'   ><img src={search}></img></Button></InputGroup.Text>
              <FormControl id="inlineFormInputGroup" placeholder={t("pesquisar")} style={{ background: "rgba(196, 196, 196, 0.5)", border: "0px", marginLeft: "0px" }} />
            </InputGroup>
          </Col>
        </Row>



      </Container>
    </> :

      <Row className="justify-content-md-center">
        <Col xs lg="10">
          <Row>
            <Col xs lg="4">
              <Row>
                <Col xs lg="7">
                  <InputGroup className="mb-2">
                    <InputGroup.Text className="search_navbar_icon" ><Button onClick={value => searchClick()} className='btn_warning' ><img src={search}></img></Button></InputGroup.Text>
                    <FormControl id="inlineFormInputGroup" placeholder={t("pesquisar")} className="search_navbar" />
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col xs lg="6">
                  <Form.Select className="select-language" value={i18n.language} onChange={language => ChangeL(language.target.value)} >
                    <option value="pt" key="pt">{t("pt")}</option>
                    <option value="en" key="en">{t("en")}</option>
                    <option value="fr" key="fr">{t("fr")}</option>
                    <option value="de" key="de">{t("de")}</option>
                  </Form.Select>
                </Col>
              </Row>
            </Col>
            <Col xs lg="4" className="logo textAligncenter"><a href="/"> <span>Clock(</span><span className="logo_re">Re</span><span>)Cycle</span> </a></Col>
            <Col xs lg="4" className="textAlignright">
              <Row>
                <Col xs lg={{ span: 3, offset: 9 }} className="textAligncenter">
                  <a href="/waitlist" id='clockLink'>
                    <div className="clock">
                      <div className="hour"></div>
                      <div className="min"></div>

                    </div>
                  </a>
                </Col>
              </Row>
              <Row>
                <Col xs lg={{ span: 3, offset: 9 }} className="textAligncenter">
                  <p className=" whaitlist_navbar_text">{t("menu_waitlist")}</p>
                </Col>
              </Row>


            </Col>
          </Row>
          <Row className="mb-13 justify-content-md-center textAligncenter navbar_menu">
            <Col xs lg="3">
              <a href="/manifest" className={page == "Manifesto" ? "hover_underline_menu_selected" : "hover_underline_menu"}> {t("menu_manifesto")}</a>
            </Col>
            <Col xs lg="3"> <a href="/about" className={page == "SobreNos" ? "hover_underline_menu_selected" : "hover_underline_menu"}>{t("menu_sobre_nos")}</a></Col>
            <Col xs lg="3">
              <a href="/contact" className={page == "Contacto" ? "hover_underline_menu_selected" : "hover_underline_menu"}>{t("menu_contactos")}</a>
            </Col>
          </Row>
        </Col>
      </Row>
    }

    </>

  );
}

export default NavbarComponent;
