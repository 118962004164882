
import React, { useState } from 'react';
import NavbarComponent from '../Components/Navbar';
import FooterComponent from '../Components/Footer';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import {
  ScrollingProvider,
  useScrollSection,
  Section,
} from 'react-scroll-section';
import email from './../Assets/email.svg';
import telefone from './../Assets/telefone.svg';
import localizacao from './../Assets/localizacao.svg';

import DOMPurify from 'dompurify';
import i18n from 'i18next';
i18n.changeLanguage(localStorage.getItem("language"));
export default class Contacto extends React.Component {

 

  scrollToTestDiv = value => {
    const divElement = document.getElementById('sobrenos');
    divElement.scrollIntoView({ behavior: 'smooth' });
  }

  render() {
    return ( <> { isMobile && window.innerWidth < 720 ? <>
     <Container  className="navbar_fixed">
        <NavbarComponent page={"Contacto"}></NavbarComponent>
      </Container>
      <Container style={{paddingRight:"16px",paddingLeft:"16px",marginTop:"120px"}}>
        <Row className="mb-47">
          <Col xs lg="12">
            <img src="/Assets/mobile/mobile_top_contactos.jpg" style={{ width: '100%' }}></img>

          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col xs lg="10">
            <Section id="sobrenos">
              <Row className="justify-content-md-center">

                <Col xs lg="10" className="mb-24">
                  <p className="IMB_Title">{i18n.t("titulo_contactos_titulo")}</p>
                </Col>
              </Row>
              <Row className="justify-content-md-center mb-80" >

                <Col xs lg="10">
                  <p className="WORK_Description">{i18n.t("texto_contactos_texto")}<br />
                  <div dangerouslySetInnerHTML={{__html:  DOMPurify.sanitize(i18n.t("texto_contactos_texto_1")) }}></div>
                  </p><p>
                  <div dangerouslySetInnerHTML={{__html:  DOMPurify.sanitize(i18n.t("texto_contactos_texto_2")) }}></div></p>
                </Col>
              </Row>
              <Row className="justify-content-md-center mb-16 textAligncenter" >
                <Col xs lg="12">
                  <img src={email}></img>
                </Col>
              </Row>
              <Row className="justify-content-md-center mb-40 textAligncenter" >
                <Col xs lg="12">
                  <p className="WORK_Description">
                    geral@clockrecycle.com
                  </p>
                </Col>
              </Row>
              <Row className="justify-content-md-center mb-16 textAligncenter" >
                <Col xs lg="10">
                  <img src={telefone}></img>
                </Col>
              </Row>
              <Row className="justify-content-md-center mb-40 textAligncenter" >
                <Col xs lg="12">
                  <p className="WORK_Description">
                    Tel. +351 213 428 304<br />
                    Tel. +351 915 772 064
                  </p>
                </Col>
              </Row>
              <Row className="justify-content-md-center mb-16 textAligncenter" >
                <Col xs lg="12">
                  <img src={localizacao}></img>
                </Col>
              </Row>
              <Row className="justify-content-md-center mb-80  textAligncenter" >
                <Col xs lg="12">
                  <p className="WORK_Description">
                    Rua do Cedofeita 342/348 <br /> 4050-174 Porto
                  </p>
                </Col>
              </Row>
            </Section>
          </Col>
        </Row>
      </Container>
      <Container className="mt-3">
        <FooterComponent></FooterComponent>

      </Container>
    </> : <>
      {/* DESKTOP */}
      <Container className="mt-3">
        <NavbarComponent page={"Contacto"}></NavbarComponent>

      </Container>
      <Container>

        <Row className="justify-content-md-center">
          <Col xs lg="10">
            <Row className="mb-47">
              <Col xs lg="12" style={{ padding: "0px" }}>
                <img src="/Assets/ftg_contactos.jpg" style={{ width: '100%' }}></img>

              </Col>
            </Row>

            <Row className="justify-content-md-center">

              <Col xs lg="10" className="mb-24">
                <p className="IMB_Title"> {i18n.t("titulo_contactos_titulo")}</p>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-80" >

              <Col xs lg="10">
                <p className="WORK_Description"> {i18n.t("texto_contactos_texto")}<br />
                <div dangerouslySetInnerHTML={{__html:  DOMPurify.sanitize(i18n.t("texto_contactos_texto_1")) }}></div></p><p>
                <div dangerouslySetInnerHTML={{__html:  DOMPurify.sanitize(i18n.t("texto_contactos_texto_2")) }}></div></p>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-40" >
              
              <Col xs lg="10">
              <p className="WORK_Description">
              <img src={email}></img>  geral@clockrecycle.com
                </p>
              </Col>
            </Row>
           
            <Row className="justify-content-md-center mb-40" >
              <Col xs lg="10">
                <p className="WORK_Description">
                <img src={telefone}></img> Tel. +351 213 428 304<br />
                  <span style={{marginLeft:"30px"}}>Tel. +351 915 772 064</span>
                </p>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-121" >
              <Col xs lg="10">
                <p className="WORK_Description">
                <img src={localizacao}></img> Rua do Cedofeita 342/348 <br /> <span style={{marginLeft:"24px"}}> 4050-174 Porto</span>
                </p>
              </Col>
            </Row>

          </Col>
        </Row>
      </Container>
      <div className="FooterAll">
        <FooterComponent></FooterComponent>

      </div>
    </>}

    </>
    );
  }
}


