export const TRANSLATIONS_EN = {
  // Linguas 
  pt: "PORTUGUESE",
  en: "ENGLISH",
  fr: "FRENCH",
  de: "GERMAN",
  // Menu 
  pesquisar: "Search",
  menu_manifesto: "MANIFEST",
  menu_sobre_nos: "ABOUT US",
  menu_contactos: "CONTACTS",
  menu_waitlist: "WAITLIST",


    // Pesquisa

    titulo_pesquisa: "Search Results",

  // Home 
  titulo_home_foto: "Invest Green <br/> Reduce Your Eco Guilt",
  botao_home_sobre_nos: "ABOUT US",
  titulo_home_green_investing: "Green Investing and Us",
  texto_home_green_investing: "We work side by side with one of the most polluting industries in the world. Since we are aware of this reality, we decided it was time to take a stand and make the difference.",
  texto_home_green_investing_2: `By taking advantage of the emotional aspect that is investing in something that passions you it and by keeping the focus on the rationality of converting an investment into money, Clock (Re)Cycle is born with the desire to seal the promise of "sustainable luxury".`,


  texto_home_cote: "Why not be the example for the industry that helping the environment should be our mission and not our consequence?",

  texto_home_geracao: "Inheritance is the most distinctive and beautiful characteristic that vintage pieces holds. The possibility of bringing on our wrist decades of history, years of memories.",
  texto_home_geracao_2: "At Clock (Re)Cycle we want to honor and preserve the soul of each piece as something unique and timeless, reminding you that investing in vintage is also an investing in the continuity of memories.",

  texto_home_knowhow: "<p></p>Through the expertise and  know-how  of more than 40 years in the market of the team behind this project, we guarantee that your investment is safe and reliable, giving you a position of trust and stability.</p>At Clock (Re)Cycle we are dedicated to the daily search of unique pieces for you, but since vintage is something extremely personal, we invite you to share with us your pieces of choice, so that we can increasingly adjust ourselves to your preferences. ",

  botao_home_waitlist: "WAITLIST",
  titulo_home_paralax:"Clock Tree Circle ",
  titulo_home_vanguarda: "At the Forefront of Change ",
  texto_home_vanguarda: "Clock (Re)Cycle underlies its action on the most circular and sustainable that can exist: the vintage market.",
  texto_home_vanguarda_2: "However, we want more. We want to encourage our clients to truly be part of the change, but we also want to challenge ourselves daily as a company and make our operations increasingly sustainable.",
  texto_home_vanguarda_3: `Therefore we created the "Clock Tree Circle" initiative, where we commit to plant 1 tree for each investment, reducing the ecological footprint that the transport of our goods implies.`,


  // MANIFESTO 
  botao_manifesto_botao: "MANIFEST",
  texto_manifesto_cote: "It's a bittersweet feeling when we know that we are performing an activity or enjoying a moment that is contributing to the increase of the ecological footprint, but at the same time, it gives us with pleasure – this is how Eco Guilty Investing/Buying is born – where we invest/buy something sustainable to lessen our sense of guilt for less sustainable behaviors",
  texto_manifesto_core: "Clock (Re)cycle’s core is, as the name implies, based on our initiative as a company to integrate the processes of reduction, recycling, and reuse in all our operations and processes.We fight for the recycling and reuse of raw materials almost endlessly, encouraging the re-introduction into the market of our pieces, avoiding the need for mass production and a new cycle of pollution, therefore supporting the increase in circularity of scale.The longevity of sustainability becomes a reality.",
  texto_manifesto_clocktreecircle: "We created the <span style='color:#004225;font-family: \"Work Sans Medium\"'>Clock Tree Circle</span>  initiative with the aim of outperforming ourselves as company on sustainability and circularity, as an opportunity to make our performance even greener, reducing the negative environmental impact inherent in the transportation of pieces, and contributing to an increase in the circularity of scale. Every time you make an investment with us, we're going to plant a native tree.<p>We estimate that by 2031 we have planted more than 3200 trees, we invite you to be part of this new sustainable community and have a real impact on the fight for environmental protection.</p>",


  // SOBRE NOS 
  titulo_sobrenos_titulo: "Get to Know Us ",
  texto_sobrenos_clock: "At Clock (Re)Cycle we are committed to contribute daily to the reduction of the ecological footprint and to the increase of the circularity of scale, encouraging green investing and reducing the Eco Guilty Living, through our performance in the vintage watch making industry.",
  texto_sobrenos_clock_2: "Our premise is to encourage and allow our investors the possibility to associate their passion for watchmaking with ecological awareness.",
  botao_sobrenos_manifesto: "MANIFEST",
  titulo_sobrenos_core: "Our core is intrinsically green.",
  texto_sobrenos_core: `We work every day to offer you the possibility of a safe investment, capable of generating a financial return greater than its initial investment, with the possibility of contributing directly and essentially to the lowering and compensation of the ecological footprint of the extracting industry, protecting nature and preventing the practice of "abandoning without recycling".`,
  texto_sobrenos_equipa: "Our team has over 40 years of experience in the market of buying and evaluating vintage luxury watches. Their vast knowledge and expertise in the area make them the ideal team to lead this project, granting confidence and credibility in every step of the process.",
  titulo_sobrenos_valores: "Our values",
  texto_sobrenos_valores_ecologica: "Ecological Awareness",
  texto_sobrenos_valores_heranca: "Heritage",
  texto_sobrenos_valores_credibilidade: "Credibility",
  texto_sobrenos_cote: "The possibility of investing in unique and soulful pieces, which transport us to a new dimension of environmental awareness with a kind of exclusivity that only the vintage entails.",

  // Contactos 

  titulo_contactos_titulo: "CONTACT US",
  texto_contactos_texto: "At Clock (Re)cycle we want to ensure that all your questions are handled as quickly and effectively as possible. ",
  texto_contactos_texto_1: "<p>Therefore,our Costumer Service team is at your service to clarify any questions that may arise, do not hesitate to contact us between working hours as below:</p></br></br> 8am – 6pm (GMT)",
  texto_contactos_texto_2: "We are at 1 click distance!",


  // WaitList 

  titulo_waitlist: "Waitlist",
  texto_waitlist: "Can’t find what you're looking for? We'll do the search for you.",
  texto_waitlist_2: "At Clock (Re)cycle we want to help you find your favorite pieces, so we designed our Waitlist. Here you can describe the watches you would like to see on our website and we will do our best to fulfill your requests.",
  texto_waitlist_3: "Let the search begin!",
  texto_waitlist_nome: "Name",
  texto_waitlist_email: "Email Address",
  texto_waitlist_descricao: "Description",
  texto_waitlist_upload: "Photo Upload",
  botao_waitlist_upload: "PHOTO UPLOAD",
  botao_waitlist_enviar: "SEND PHOTO",


  // Legal 

  

  texto_legal_1:"Legal Provisions",
  texto_legal_2:"Terms and Conditions",
  texto_legal_3:"Terms",
  texto_legal_4:`<p>Clock (Re)Cycle, is the owner of this site and, unless otherwise indicated, of all contents included therein.</p>
  <p>By accessing and using this site, you agree to the terms and conditions set out below. Please note that Clock (Re)Cycle may at any time and at its discretion change the terms and conditions, therefore recommending that you review them regularly.</p>
  <p>If any of these terms is found to be illegal, invalid or void, the validity of the remaining terms will not be affected or decreased.</p>`,
  texto_legal_5:"Use",
  texto_legal_6:`<p>The contents of this site may only be used for personal information.</p>
  <p>This means that you can view, copy, print and distribute any document published here by Clock (Re)Cycle,provided that you are not for the purpose of marketing thecontent, and provided that reference is made to Clock (Re)Cycle in the process of distributing the information and that all copyright messages and other copyright mentions of the content sit up.</p>`,
  texto_legal_5:"Copyright",
  texto_legal_7:`<p>All images, company logo, company name, texts and software included on this site are, unless otherwise indicated, owned by Clock (Re)Cycle and are protected by industrial property laws.</p>
  <p>Any and all use of the logo, name, text, images and software is prohibited, except under the conditions expressed on the website.</p>
  <p>The unauthorized use or distribution of any material on this site may be subject to civil or criminal penalties in accordance with applicable laws.</p>`,
  texto_legal_8:"Information Policy",
  texto_legal_9:`<p>Clock (Re)Cycle does not undertake to update the information or materials contained on the website. Changes to the information may be made at any time without prior notification.</p>
  <p>Clock (Re)Cycle shall not be in any situation accountable for any losses, direct or indirect, arising from the use or impossibility of using this website or its content.</p>`,
  texto_legal_10:"Cookies Policy",
  texto_legal_11:"What are Cookies?",
  texto_legal_12:"<p>Cookies are files that are recorded on your computer and have the sole purpose of simplifying your navigation on the website (these cookies cannot contain viruses or be executed, as they are not active files).</p>",
  texto_legal_13:"<p>Cookies allow you to:</p>",
  texto_legal_14:`<li>Improve the browsing experience and take advantage of our personalized advice;</li>
  <li>Be informed about our promotional offers in various products;</li>
  <li>Store the products in your shopping cart so that you can continue the order at any time;</li>
  <li>These cookies can only be read by Clock (Re)Cycle  and by the user, who can delete them if desired.</li>`,
  texto_legal_15:"Cookies used:",
  texto_legal_16:"<p>Essential Cookies – These cookies enable essential features such as security, identity verification and network management. These cookies cannot be disabled.</p>",
  texto_legal_17:"<p>Marketing Cookies – These cookies are used to search for the effectiveness of advertising, provide a more relevant service and better ads to suit your interests.</p>",
  texto_legal_18:"<p>Functional Cookies – These cookies collect data to remember the choices users make and to improve and provide a more personalized experience.</p><p>Analytical Cookies – These cookies help usunderstand  how visitors interact with our    website, discover errors and provide better general analysis.</p>",
  texto_legal_19:"Other sites",
  texto_legal_20:"<p>This sitecontains/may contain links to third-partysites. However, these links are made available for the convenience of our customers and Clock (Re)Cycle  assumes no responsibility for the contents, accuracy of the information or privacy policies of those same sites.</p>",
  texto_legal_21:"Contact",
  texto_legal_22:"If you have any questions regarding these terms and conditions or in particular, the privacy policy or exact location of the parts, please do not hesitate to contact us at  geral@clockrecycle.com.",
// Politica de Privacidade 

texto_politica_1:"Privacy Policy",
texto_politica_2:"Confidentiality",
texto_politica_3:`<p>The  Clock Company (Re)Cycle, guarantees theconfidentiality and protection of all personal data collected, pursuant to the GDPR (General Data Protection Regulation), and in strict respect for the rights and guarantees enshrined in the Constitution and the law.</p>
<p>Information and consent for the processing of data</p>
<p>The collection of the elements necessary for the legal and fiscal validation of contracts or transaction documents, as well as the preparation of a client form enabling the traceability underlying the possible business relationship, in compliance with legal obligations, to be fulfilled, corresponds to an act of collection of personal data, which will then be the subject of organization, conservation,  consultation and use, and is subject to the application of Regulation (EU) 2016/679 of the European Parliament and the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and the free movement of such data.</p>
<p>In the process of the personal data being lawful, the holder of the personal data is requested to read the following information and to confirm the information received and/or give his consent to the processing.</p>
<p>Identification of the controller</p>
<p>Enterprise: Clock (Re)Cycle</p>
<p>Registration Number/NIPC: 516609459</p>
<p>Address: Rua de Cedofeita 348, 4050-174, Porto</p>
<p>Telephone contact: 223220908</p>
<p>E-mail: geral@clockrecycle.com</p>
`,
texto_politica_4:"Purpose of Treatment",
texto_politica_5:`<p>The purpose of the processing of personal data is to legally and fiscally validate the contracts and documents underlying them, such as those relating to payments and transactions, as well as the creation of an individual client/supplier form, to comply with legal obligations of conservation, communication, examination and supervision in business relations. Contact details will serve to fulfill any duty of additional information or rectification necessary within the restricted scope of the underlying contracts.</p>
<p>In case of explicit consent of the data subject, contact details may be used to request additional consent to the processing of the data, as well as to send information or invitations to events or campaigns in the context of the company's commercial activity.</p>
<p>Mandatory provision of data</p>
<p>The provision of personal data to the Clock Company(Re)Cycle is an essential condition for carrying out transactions, whenever the legislation requires the identification of suppliers /private customers. </p>`,
texto_politica_6:"Category of personal data collected",
texto_politica_7:`The personal data collected is (i) identification data (such as: identification document, full name, residence, taxpayer number); (ii) contact details (such as: telephone number and email); (iii) all elements collected in the copy of the citizen card or in the collection by means of a "chip" or other identification document considered valid by law for commercial transactions under the RJOC and LBCFT Act.`,
texto_politica_8:"Recipients or Categories of Recipients of Personal Data",
texto_politica_9:`<p>The data collected will serve solely and exclusively for the purpose described above, and will be processed in the strict compliance with the duties laid down in the GDPR and other legislation. Where legally binding, the communication of personal data to judicial authorities and other entities provided for in the legislation shall be made available by the means made available by them.</p>
<p>For the administrative processing of documents that contain personal data, the data protection officer directly assumes the procedures, or delegates to a third party hierarchically subordinate, under his direct supervision, contractualizing the duty of confidentiality and protection.</p>
<p>In the case of outsourcing of services that process personal data under their responsibility and protection, the duty of confidentiality and protection, verifying compliance with the GDPR, as well as the physical and procedural conditions of data processing and protection, will be contracted.</p>`,
texto_politica_10:`Period of Retention of Personal Data`,
texto_politica_11:`<p>Personal data shall be kept for the time limits inherent in the fulfilment of the legal obligations, in the case of documents underlying commercial contracts or transactions and the client's form, for the period considered legal by Portuguese law for the act(s) that are the origin of its collection and retention, in the case of any other processing of data under the express consent of its holder.</p>`,
texto_politica_12:`Rights of the holder of personal data`,
texto_politica_13:`<p>the right to request access to personal data concerning them;</p>
<li>right to rectification;</li>
<li>right to their payment;</li>
<li>the right to limitation of processing with regard to the data subject;</li>
<li>right to owe treatment;</li>
<li>right to data portability;</li>
<li>right to withdraw consent to the processing of data at any time, with the consequent payment of personal data;</li>
<li>right to lodge complaints with the CNPD.</li>
`,
texto_politica_14:"Right of Access to the Data Subject",

texto_politica_15:`<p>The right to request access to personal data means the right to obtain from the controller confirmation that personal data concerning him or her are or are not processed and, where appropriate, the right to access his personal data and the information contained in Article 15 of Regulation (EU) 2016/679 of the European Parliament and the Council of 27 April 2016.</p>
<p>The right of access also includes the right to obtain a copy of the personal data, which will be provided in an electronic format of common use, when the request is made by electronic means. If the request is made by traditional mail, unless otherwise requested, the copy of the personal data will be provided in the same way.</p>
<p>In the case of other requests for copies of personal data, the cash amount may be charged to cover administrative costs.</p>
<p>Where the right to obtain a copy of personal data may prejudice or call into question the rights and freedoms of third parties, the copy shall not include such data.</p>`,
texto_politica_16:"Right of Rectification",
texto_politica_17:`<p>The holder has the right to obtain, without undue delay, from the controller the rectification of inaccurate personal data concerning him. Taking into account the purposes of the processing, the data subject is entitled to complete his/her incomplete personal data, including by means of an additional statement.</p>
<p>Right to The Payment of Data ('Right to be Forgotten')</p>
<p>The data subject has the right to obtain from the controller the erasure of his personal data without undue delay, and the data subject has the right to erase personal data without undue delay when any of the reasons contained in Article 17 of Regulation (EU) 2016/679 of the European Parliament and the Council of 27 April 2016 apply,  in particular, where the data subject withdraws the consent on which the processing of the data is based and if there is no other legal basis for such processing.</p>`,
texto_politica_18:`Form of exercise of rights`,
texto_politica_19:`The exercise of the rights of the holder of the personal data may take place electronically through the area reserved on the https://www. clockrecycle.com/, by telephone, provided that the identity of the holder is proven or by means of a written request with a notarised signature addressed to the premises of Clock  (Re)Cycle.`,
texto_politica_20:`Data Subject Statement`,
texto_politica_21:`
<p>I have become aware of all the above-mentioned procedures, obligations and rights, inherent in the collection and processing of personal data.</p>
<p>I express my informed consent to the processing of my personal data in accordance with the preceding text and in the following terms:</p>
<p>My contact details may be used to request additional consent to the processing of the data, as well as to send information or invitations to events or campaigns in the context of the commercial activity of the company Clock  (Re)Cycle.</p>
<p>My personal data will be kept for the mandatory legal period for compliance with Portuguese law, namely the Laws; RJOC, LCBFT and time limits of the TA and up to 180 days after the end of the deadlines mentioned hereafter the last contact established between the parties, being guaranteed all the rights that assist me as the holder said data, in accordance with the GDPR and other legislation that protects them. At the end of this period, the data will be destroyed safely and permanently, both on the original media and in all existing copies thereafter.</p>
<p>The data controller:</p>
<p>Clock (Re)Cycle</p>`,
texto_politica_22:`<p>Money Laundering and Terrorist Financing ActUnder ACT BCFT 83/2017 of 18 August, limits to:`,
texto_politica_23:
`<p>Cash payment up to 999,99€.</p>
<p>Cash receipt:</p>
<p>999,99€ – Company (i)</p>
<p>2.999,99€ - Private. Above these amounts only banking means are accepted and must provide name and address.</p>
<p>9.999,99€ - Individuals without tax residence in Portugal. They must prove tax residence outside Portugal.</p>
<p>Over 15,000.00 € -  All customers are identified through Annex I of the ASAEor Annex Companies,  independenton the means of payment.</p>
<p>(i) Where they wish to invoice with a name and corporate contributor (Legal Person) they must prove that they are authorized by that company to make acquisitions on behalf of the company, regardless of the value.</p>
`,

// Livro Reclamacoes

texto_reclamacao_1:`Online Conflict Resolution `,
texto_reclamacao_2:`<p>Expensive customer,</p>
<p>The European Union has set up a website to support all consumers to lodge their complaints about any dispute in which they are involved. In this regard, Clock (Re)Cycle makes all the information available so that it can exercise its right of complaint to an official body, third party and impartial to the case, ('dispute resolution entity') that will help you resolve the dispute in question.</p>
<p>Thus, if you were dissatisfied with the purchase of a good or service on our website, or with the solution presented by us to resolve the situation, you can access this official website https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=PT and expose your complaint.</p>`,
texto_reclamacao_3:`What is alternative dispute resolution?`,
texto_reclamacao_4:`
<p>Alternative dispute resolution is the possibility that all consumers have at their disposal to use official entities to assist them in the resolution, or orientation of a conflict, before opening litigious proceedings in the Courts.Usually the procedure is as follows: the client asks an impartial third party to act as a mediator between themselves and the trader who is the target of their complaint. The mediator may suggest a solution to your complaint, or bring the parties together to find a solution. Alternative dispute resolution is usually less expensive, less formal and faster than the judicial route.
</p><p>Clock (Re)Cycle provides the websites of some entities that can help you present your arguments:</p>
<p>CACCL - Center for Arbitration of Consumer Disputes ofthe Port http://www.centroarbitragemlisboa.pt/ </p>
<p>CICAP – - Consumer Conflict Arbitration Center https://www.cicap.pt/</p>`,
texto_reclamacao_5:`Complaints Book`,
texto_reclamacao_6:`You can access the complaintbook online via the link below.`,
texto_reclamacao_7:`<a href="https://www.livroreclamacoes.pt/Inicio/">https://www.livroreclamacoes.pt/Inicio/</a>`,






  // Footer


  footer_newsletter: "Join our Newsletter:",
  footer_email: "Email*",
  footer_subscrever: "SUBSCRIBE",
  footer_manifesto: "MANIFEST",
  footer_sobre_nos: "ABOUT US",
  footer_contactos: "CONTACTS",
  footer_apoio_cliente: "CLIENT SUPPORT",
  footer_politicas: "Privacy Policies",
  footer_legais: "Legal Provisions",
  footer_reclamacoes: "Complaint Book"

}