
import React, { useState } from 'react';
import NavbarComponent from '../Components/Navbar';
import FooterComponent from '../Components/Footer';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import {
  ScrollingProvider,
  useScrollSection,
  Section,
} from 'react-scroll-section';
import cote from './../Assets/cote.svg';
import watch from './../Assets/watch.svg';

import i18n from 'i18next';
i18n.changeLanguage(localStorage.getItem("language"));
export default class Manifesto extends React.Component {

  componentDidMount(){
    var language = localStorage.getItem("language");

    if (language != i18n.language && language != undefined && language != "" ){
      i18n.changeLanguage(language);
    }
  }
 

  scrollToTestDiv = value => {
    const divElement = document.getElementById('manifesto');
    divElement.scrollIntoView({ behavior: 'smooth' });
  }

  ScrollMobile = value =>{
    var element = document.getElementById('manifesto');
    var headerOffset = 120;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
  
    window.scrollTo({
         top: offsetPosition,
         behavior: "smooth"
    });
  }

  render() {
    return ( <> { isMobile && window.innerWidth < 720 ? <>
      <Container  className="navbar_fixed">
        <NavbarComponent page={"Manifesto"}></NavbarComponent>
      </Container>
      <Container style={{paddingRight:"16px",paddingLeft:"16px",marginTop:"120px"}}>
        <Row className="mb-47">
          <Col xs lg="12" >
            <img src="/Assets/mobile/ftg_manifesto 6.jpg" style={{ width: '100%' }}></img>
           
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col xs lg="10">
            <Row>
              <Col xs lg={{ span: 6, offset: 3 }} className="mb-80 textAligncenter">
                <Button onClick={this.ScrollMobile}>{i18n.t("botao_manifesto_botao")}</Button>
              </Col>
            </Row>
            <Section id="manifesto">
            <Row className="justify-content-md-center">
                <Col xs lg="1" className="textAligncenter mb-33">
                  <img src={cote}></img>
                </Col>
              </Row>
              <Row className="justify-content-md-center textAligncenter mb-33">
                <Col xs lg="8" >
                  <p className="IMB_Title_green textAligncenter"><div dangerouslySetInnerHTML={{__html: (i18n.t("texto_manifesto_cote"))}}></div></p>
                </Col>
              </Row>
              <Row className="justify-content-md-center  mb-64">
                <Col xs lg="1" className="textAligncenter">
                  <img src={cote} style={{transform: "scaleX(-1)"}}></img>
                </Col>
              </Row>
              <Row>
                <Col xs lg="12" className="mb-40">
                  <img src="/Assets/mobile/ftg_manifesto05 2.jpg" style={{ width: '100%' }}></img>
                </Col>
                </Row>
                <Row>
                <Col xs lg="12" className="mb-40">
                  <img src="/Assets/mobile/ftg_manifesto04 2.jpg" style={{ width: '100%' }}></img>
                </Col>
              </Row>
              <Row className="justify-content-md-center mb-80">
                <Col xs lg="10">
                  <p><div dangerouslySetInnerHTML={{__html: (i18n.t("texto_manifesto_core"))}}></div></p>

                </Col>
              </Row>
              <Row className="mb-80">
                <Col xs lg="12" className={"parallax_image_mobile"}>
                <p className="titulo_paralax_mobile"> {i18n.t("titulo_home_paralax")}</p>
                </Col>
              </Row>
              
              <Row className="justify-content-md-center mb-80">
                <Col xs lg="10" >

                  <p className="WORK_Description"><div dangerouslySetInnerHTML={{__html: (i18n.t("texto_manifesto_clocktreecircle"))}}></div></p>

                </Col>
              </Row>
              <Row className="justify-content-md-center mb-80">
                <Col xs lg="10" className="textAligncenter" >
                <a href="#"  ><img src="/Assets/btn_up.svg"  style={{width:"50px"}}></img></a>
                </Col>
              </Row>
            </Section>
          </Col>
        </Row>
      </Container>
      <Container className="mt-3">
        <FooterComponent></FooterComponent>

      </Container>
    </> : <>
    {/* DESKTOP */}
      <Container className="mt-3">
        <NavbarComponent page={"Manifesto"}></NavbarComponent>

      </Container>
      <Container>
        <Row className="mb-47">
          <Col xs lg="12" style={{ padding: "0px" }}>
            <img src="/Assets/ftg_manifesto 1 (1).jpg" style={{ width: '100%',maxHeight:"644px" }}></img>
           
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col xs lg="10">
            <Row>
              <Col xs lg={{ span: 4, offset: 4 }} className="mb-121 textAligncenter">
                <Button onClick={this.scrollToTestDiv}>{i18n.t("botao_manifesto_botao")}</Button>
              </Col>
            </Row>
            <Section id="manifesto">              
              <Row className="justify-content-md-center">
                <Col xs lg="1" className="textAligncenter mb-33">
                  <img src={cote}></img>
                </Col>
              </Row>
              <Row className="justify-content-md-center mb-33">
                <Col xs lg="8">
                  <p className="IMB_Title_green"><div dangerouslySetInnerHTML={{__html: (i18n.t("texto_manifesto_cote"))}}></div></p>
                </Col>
              </Row>
              <Row className="justify-content-md-center  mb-64">
                <Col xs lg="1" className="textAligncenter">
                  <img src={cote} style={{transform: "scaleX(-1)"}}></img>
                </Col>
              </Row>
              <Row className="justify-content-md-center mb-80">

                <Col xs lg="8">
                  <img src="/Assets/ftg_manifesto05 1.jpg" style={{ width: '100%' }}></img>
                </Col>
                <Col xs lg="4">
                  <img src="/Assets/ftg_manifesto04 1.jpg" style={{ width: '100%',maxHeight:'527px' }}></img>
                </Col>
              </Row>
              <Row className="justify-content-md-center mb-40">
                <Col xs lg="10" >
                  <p className="WORK_Description"><div dangerouslySetInnerHTML={{__html: (i18n.t("texto_manifesto_core"))}}></div></p>

                </Col>
              </Row>
            
              <Row className="mb-80">
                <Col xs lg="12" className={"parallax_image"}>
                <p className="titulo_paralax"> {i18n.t("titulo_home_paralax")}</p>
                </Col>
              </Row>
              <Row className="justify-content-md-center mb-80">
                <Col xs lg="10" >

                  <p className="WORK_Description"><div dangerouslySetInnerHTML={{__html: (i18n.t("texto_manifesto_clocktreecircle"))}}></div> <br/>

</p>

                </Col>
              </Row>
              <Row className="justify-content-md-center mb-121">
                <Col xs lg="10" className="textAligncenter" >
                <a href="#"  ><img src="/Assets/btn_up.svg"  style={{width:"50px"}}></img></a>
                </Col>
              </Row>
            </Section>
          </Col>
        </Row>
      </Container>
      <div className="FooterAll">
        <FooterComponent></FooterComponent>

      </div>
    </>}

    </>
    );
  }
}


