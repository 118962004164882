
import React, { useState } from 'react';
import NavbarComponent from '../Components/Navbar';
import FooterComponent from '../Components/Footer';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import {
  ScrollingProvider,
  useScrollSection,
  Section,
} from 'react-scroll-section';
import cote from './../Assets/cote.svg';
import watch from './../Assets/watch.svg';
import credibilidade from './../Assets/SobreNos_Credibilidade 1.svg';
import historia from './../Assets/SobreNos_Historia 1.svg';
import sustentabilidade from './../Assets/SobreNos_Sustentabilidade 1.svg';

import i18n from 'i18next';
i18n.changeLanguage(localStorage.getItem("language"));
export default class SobreNos extends React.Component {



  scrollToTestDiv = value => {
    const divElement = document.getElementById('manifesto');
    divElement.scrollIntoView({ behavior: 'smooth' });
  }

  render() {
    return ( <> { isMobile && window.innerWidth < 720 ? <>
      <Container className="navbar_fixed">
        <NavbarComponent page={"SobreNos"}></NavbarComponent>
      </Container>
      <Container style={{ paddingRight: "16px", paddingLeft: "16px", marginTop: "120px" }}>
        <Row className="justify-content-md-center">
          <Col xs lg="10">
            <Row className="mb-40">
              <Col xs lg="12" >
                <img src="/Assets/mobile/ftg_quemsomos_02 2.jpg" style={{ width: '100%' }}></img>

              </Col>
            </Row>

            <Row className="justify-content-md-center">
              <Col xs lg="10" className="mb-24">
                <p className="IMB_Title">{i18n.t("titulo_sobrenos_titulo")}</p>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-40" >

              <Col xs lg="10">
                <p className="WORK_Description">{i18n.t("texto_sobrenos_clock")}</p>
                <p>{i18n.t("texto_sobrenos_clock_2")}</p>


              </Col>
            </Row>
            <Row>
              <Col xs lg={{ span: 6, offset: 3 }} className="mb-80 textAligncenter">
                <a href="/manifest" className="btn btn-primary">{i18n.t("botao_sobrenos_manifesto")}</a>
              </Col>
            </Row>
            <Section id="manifesto">

              <Row className="justify-content-md-center mb-80">

                <Col xs lg="12" className="mb-40">
                  <img src="/Assets/mobile/waitlist 2.jpg" style={{ width: '100%' }}></img>
                </Col>
              </Row>
              <Row className="justify-content-md-center mb-80">
                <Col xs lg="10">
                  <p>{i18n.t("titulo_sobrenos_core")}
                    {i18n.t("texto_sobrenos_core")}
                  </p>

                </Col>
              </Row>
              <Row>
                <Col xs lg="12" className="mb-40">
                  <img src="/Assets/mobile/ftg_lab 2.jpg" style={{ width: '100%' }}></img>
                </Col>
              </Row>
              <Row>
                <Col xs lg="12" className="mb-80">
                  <img src="/Assets/mobile/ftg_quemsomos04 2.jpg" style={{ width: '100%' }}></img>
                </Col>
              </Row>
              {/*

              <Row className="justify-content-md-center mb-40">
                <Col xs lg="10" >
                   <p className="WORK_Description">{i18n.t("texto_sobrenos_equipa")}<br /><br />{i18n.t("titulo_sobrenos_valores")}</p>  

                </Col>
              </Row>
            */ }
              <Row className="justify-content-md-center mb-40">
                <Col xs lg="12" className="textAligncenter" >
                  <img src={sustentabilidade} style={{ width: '52px' }}></img>
                  <p>{i18n.t("texto_sobrenos_valores_ecologica")}</p>
                </Col>
              </Row>
              <Row className="justify-content-md-center mb-40">
                <Col xs lg="12" className="textAligncenter">
                  <img src={historia} style={{ width: '52px' }}></img>
                  <p>{i18n.t("texto_sobrenos_valores_heranca")}</p>
                </Col>
              </Row>
              <Row className="justify-content-md-center mb-80">
                <Col xs lg="12" className="textAligncenter">
                  <img src={credibilidade} style={{ width: '52px', marginLeft: "25px" }}></img>
                  <p>{i18n.t("texto_sobrenos_valores_credibilidade")}</p>
                </Col>
              </Row>

            </Section>
          </Col>
        </Row>
      </Container>
      <Container className="mt-3">
        <FooterComponent></FooterComponent>

      </Container>
    </> : <>
      {/* DESKTOP */}
      <Container className="mt-3">
        <NavbarComponent page={"SobreNos"}></NavbarComponent>

      </Container>
      <Container>

        <Row className="justify-content-md-center">
          <Col xs lg="10">
            <Row className="mb-47">
              <Col xs lg="12">
                <img src="/Assets/SobreNos (4).jpg" style={{ width: '100%' }}></img>

              </Col>
            </Row>


            <Row className="justify-content-md-center">

              <Col xs lg="10" className="mb-24">
                <p className="IMB_Title"> {i18n.t("titulo_sobrenos_titulo")}</p>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-40" >

              <Col xs lg="10">
                <p className="WORK_Description">{i18n.t("texto_sobrenos_clock")}</p>
                <p>{i18n.t("texto_sobrenos_clock_2")}</p>

              </Col>
            </Row>
            <Row>
              <Col xs lg={{ span: 4, offset: 4 }} className="mb-121 textAligncenter">
                <a href="/manifest" className="btn btn-primary">{i18n.t("botao_sobrenos_manifesto")}</a>
              </Col>
            </Row>
            <Section id="manifesto">
              <Row className="justify-content-md-center mb-80">

                <Col xs lg="12">
                  <img src="/Assets/SobreNos (2).jpg" style={{ width: '100%' }}></img>
                </Col>
              </Row>
              <Row className="justify-content-md-center mb-80">
                <Col xs lg="10">
                  <p>{i18n.t("titulo_sobrenos_core")}</p>
                  <p>{i18n.t("texto_sobrenos_core")}
                  </p>

                </Col>
              </Row>
              <Row className="justify-content-md-center mb-80">

                <Col xs lg="8">
                  <img src="/Assets/SobreNos (3).jpg" style={{ width: '100%' }}></img>
                </Col>
                <Col xs lg="4">
                  <img src="/Assets/SobreNos (1).jpg" style={{ width: '100%', maxHeight: '527px' }}></img>
                </Col>
              </Row>
              {/* 
              <Row className="justify-content-md-center mb-40">
                <Col xs lg="10" >
                  <p className="WORK_Description">{i18n.t("texto_sobrenos_equipa")}
                    <br /><br />
                    {i18n.t("titulo_sobrenos_valores")}</p>

                </Col>
              </Row>
*/}
              <Row className="justify-content-md-center mb-121">
                <Col xs lg="2" className="textAligncenter" >
                  <img src={sustentabilidade} style={{ height: '50px' }}></img>
                  <p>{i18n.t("texto_sobrenos_valores_ecologica")}</p>
                </Col>
                <Col xs lg="2" className="textAligncenter" >
                  <img src={historia} style={{ height: '50px' }}></img>
                  <p>{i18n.t("texto_sobrenos_valores_heranca")}</p>
                </Col>
                <Col xs lg="2" className="textAligncenter" >
                  <img src={credibilidade} style={{ height: '50px', marginLeft: "25px" }}></img>
                  <p>{i18n.t("texto_sobrenos_valores_credibilidade")}</p>
                </Col>
              </Row>
            </Section>
          </Col>
        </Row>
      </Container>
      <div className="FooterAll">
        <FooterComponent></FooterComponent>

      </div>
    </>}

    </>
    );
  }
}


