
import React, { useState } from 'react';
import NavbarComponent from '../Components/Navbar';
import FooterComponent from '../Components/Footer';
import { Row, Col, Container, Form, InputGroup, FormControl, Button } from 'react-bootstrap';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import emailjs from 'emailjs-com';

import {
  ScrollingProvider,
  useScrollSection,
  Section,
} from 'react-scroll-section';
import cote from './../Assets/cote.svg';
import watch from './../Assets/watch.svg';

import i18n from 'i18next';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

i18n.changeLanguage(localStorage.getItem("language"));


export default class WhaitList extends React.Component {



  scrollToTestDiv = value => {
    const divElement = document.getElementById('sobrenos');
    divElement.scrollIntoView({ behavior: 'smooth' });
  }



  sendEmail(e) {
    e.preventDefault();
  

    emailjs.sendForm('service_gkturwl', 'template_t8avs4f', e.target, 'ktm8U-4nl29tDHCFM')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      toast("Sent with success!");
  }

  render() {
    return (<> {isMobile && window.innerWidth < 720 ? <>
      <Container className="navbar_fixed">
        <NavbarComponent page={"WaitList"}></NavbarComponent>
      </Container>
      <Container style={{ paddingRight: "16px", paddingLeft: "16px", marginTop: "120px" }}>
      <form className="contact-form" onSubmit={this.sendEmail}>
        <Row className="justify-content-md-center">

          <Col xs lg="10">
            <Row className="mb-47">
              <Col xs lg="12" >
                <img src="/Assets/ftg_waitlist_mobile.jpg" style={{ width: '100%' }}></img>
              </Col>
            </Row>
            <Row className="justify-content-md-center">

              <Col xs lg="10" className="mb-24">
                <p className="IMB_Title"> {i18n.t("titulo_waitlist")}</p>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-80" >

              <Col xs lg="10">
                <p className="WORK_Description">
                  {i18n.t("texto_waitlist")}<br />
                  {i18n.t("texto_waitlist_2")}
                  <br />
                </p>
                <p className="WORK_Description" style={{ fontStyle: 'italic', color: "#400102" }}>    {i18n.t("texto_waitlist_3")}</p>


              </Col>
            </Row>
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="12">
                <InputGroup className="mb-2">
                  <FormControl id="inlineFormInputGroup" placeholder={i18n.t("texto_waitlist_nome")} />
                </InputGroup>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="12">
                <InputGroup className="mb-2">
                  <FormControl id="inlineFormInputGroup" placeholder={i18n.t("texto_waitlist_email")} />
                </InputGroup>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="12">
                <InputGroup className="mb-2">
                  <Form.Control as="textarea" rows={5} placeholder={i18n.t("texto_waitlist_descricao")} />
                </InputGroup>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-16" >
              <Col xs lg="12">
                <p style={{ fontSize: "12px" }}>{i18n.t("texto_waitlist_upload")} </p>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="12">
                <input type="file" />
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-80" >
              <Col xs lg="12">
                <Button >{i18n.t("botao_waitlist_enviar")}</Button>
              </Col>
            </Row>
          </Col>
        </Row>
        </form>
      </Container>
      <Container className="mt-3">
        <FooterComponent></FooterComponent>

      </Container>
    </> : <>
      {/* DESKTOP */}
      <Container className="mt-3">
        <NavbarComponent page={"WaitList"}></NavbarComponent>

      </Container>
      <Container>
      <form className="contact-form" onSubmit={this.sendEmail}>
        <Row className="justify-content-md-center">
          <Row className="mb-47">
            <Col xs lg="12" style={{ padding: "0px" }}>
              <img src="/Assets/ftg_waitlist.jpg" style={{ width: '100%' }}></img>
            </Col>
          </Row>
          <Col xs lg="10">
            <Row className="justify-content-md-center">

              <Col xs lg="10" className="mb-24">
                <p className="IMB_Title"> {i18n.t("titulo_waitlist")}</p>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-80" >

              <Col xs lg="10">
                <p className="WORK_Description">{i18n.t("texto_waitlist")}<br />
                  {i18n.t("texto_waitlist_2")}<br />
                  <br />
                </p>
                <p className="WORK_Description" style={{ fontStyle: 'italic', color: "#400102" }}>   {i18n.t("texto_waitlist_3")}</p>


              </Col>
            </Row>
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="10">
                <InputGroup className="mb-2">
                  <FormControl id="inlineFormInputGroup" placeholder={i18n.t("texto_waitlist_nome")} name="nome" />
                </InputGroup>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="10">
                <InputGroup className="mb-2">
                  <FormControl id="inlineFormInputGroup" placeholder={i18n.t("texto_waitlist_email")} name="email" />
                </InputGroup>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="10">
                <InputGroup className="mb-2">
                  <Form.Control as="textarea" rows={5} name="descricao" placeholder={i18n.t("texto_waitlist_descricao")} />
                </InputGroup>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-16" >
              <Col xs lg="3">
                <p style={{ fontSize: "12px" }}>{i18n.t("texto_waitlist_upload")} </p>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="3">
                <input type="file" name="file" />
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-121" >
              <Col xs lg="3">
                <Button type="submit" >{i18n.t("botao_waitlist_enviar")}</Button>
              </Col>
            </Row>
          </Col>
        </Row>
        </form>
      </Container>
      <div className="FooterAll">
        <FooterComponent></FooterComponent>

      </div>
    </>}

    </>
    );
  }
}


