import React from 'react';
import { Row, Col, Container, InputGroup, FormControl, Button } from 'react-bootstrap';
import {  isMobile } from 'react-device-detect';


import i18n from 'i18next';
import { useTranslation } from "react-i18next";
import "./../translations/i18n";



const ChangeL = (language) => {
  console.log(language);
  i18n.changeLanguage(language);

  localStorage.setItem("language", language);
  localStorage.setItem("i18nextLng", language);
  window.location.reload();
  
}



function FooterComponent() {
  const { t } = useTranslation();
  return (
    <> { isMobile && window.innerWidth < 720 ? <>
      <Row className="justify-content-md-center footer">
        <Col xs lg="10">
          <Row>
            <Col xs lg="4">
              <Row>
                <Col xs lg="12">
                  <p className="footer_newsletter mt-12">{t("footer_newsletter")}</p>
                </Col>
              </Row>
              <Row>
                <Col xs lg="12">
                  <InputGroup className="mb-2">
                    <FormControl id="inlineFormInputGroup" placeholder={t("footer_email")} />
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col xs lg="12">
                  <Button className="btn-danger mb-20" >{t("footer_subscrever")}</Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row >
            <Col xs lg="12">
              <a href="/contact" className="hover_underline_menu footer_titulo  mb-35">{t("footer_contactos")}</a>

            </Col>
          </Row>
          <Row>
            <Col xs lg="12">
              <a href="/about" className="hover_underline_menu footer_titulo mb-35">{t("footer_sobre_nos")}</a></Col>
          </Row>
          <Row>
            <Col xs lg="12">
              <a href="/manifest" className="hover_underline_menu footer_titulo  mb-35">{t("footer_manifesto")}</a>
            </Col>
          </Row>
          <Row>
            <Col xs lg="12">
              <p   className="footer_titulo mb-12">{t("footer_apoio_cliente")}</p>
              <p><a   href="/privacy"className="footer_options">{t("footer_politicas")}</a></p>
              <p><a   href="/legal" className="footer_options">{t("footer_legais")}</a></p>
              <p><a  href="/complaint" className="footer_options">{t("footer_reclamacoes")}</a></p>
            
            </Col>
          </Row>

        </Col>
      </Row>
      <Row className="mb-9 justify-content-md-center footer" style={{ borderTop: "0.5px solid #333333",  }}>
        <Col xs lg="10">
          <Row className="justify-content-md-center mb-10">
            <Col xs lg="1">
            <a href="#" onClick={()=>ChangeL("pt")}  className="footer_titulo textAligncenter">{t("pt")}</a></Col>
            <Col xs lg="1">
            <a href="#" onClick={()=>ChangeL("en")} className="footer_titulo textAligncenter">{t("en")}</a></Col>
            <Col xs lg="1">
            <a href="#" onClick={()=>ChangeL("fr")} className="footer_titulo textAligncenter">{t("fr")}</a></Col>
            <Col xs lg="1">
            <a href="#" onClick={()=>ChangeL("de")} className="footer_titulo textAligncenter">{t("de")}</a></Col>
          </Row>
        </Col>
      </Row></> : <>
<Container>
      <Row className=" justify-content-md-center  footer">
        <Col xs lg="10">
          <Row  className="justify-content-md-center">
            <Col xs lg="4">
              <Row >
                <Col xs lg="12">
                  <p className="footer_newsletter mt-12 ">{t("footer_newsletter")}</p>
                </Col>
              </Row>
              <Row>
                <Col xs lg="12">
                  <InputGroup className="mb-2">
                    <FormControl id="inlineFormInputGroup" placeholder={t("footer_email")} />
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col xs lg="12">
                  <Button className="btn-danger mb-20" >{t("footer_subscrever")}</Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col xs lg="3">
              <a href="/manifest" className="hover_underline_menu footer_titulo">{t("footer_manifesto")}</a><p></p>
            
             
            </Col>
            <Col xs lg="3">
              <a href="/about" className="hover_underline_menu footer_titulo">{t("footer_sobre_nos")}</a></Col>
            <Col xs lg="3">
              <a href="/contact" className="hover_underline_menu footer_titulo">{t("footer_contactos")}</a>
            </Col>
            <Col xs lg="3">
              <p className="footer_titulo">{t("footer_apoio_cliente")}</p>
              <p className='footer_politicas'><a   href="/privacy" className="hover_underline_menu footer_options">{t("footer_politicas")}</a></p>
              <p className='footer_politicas'><a   href="/legal" className="hover_underline_menu footer_options">{t("footer_legais")}</a></p>
              <p className='footer_politicas'><a  href="/complaint" className=" hover_underline_menu footer_options">{t("footer_reclamacoes")}</a></p>
            </Col>
          </Row>

        </Col>
      </Row>
      </Container>
      
      <Row className="mb-9 justify-content-md-center footer" style={{ borderTop: "0.5px solid rgb(51 51 51 / 18%)" }}>
      <Container>
        <Col xs lg="12">
          <Row className="justify-content-md-center mb-10">
            <Col xs lg="2">
              <p onClick={()=>ChangeL("pt")}  className="footer_titulo textAligncenter hover_underline_menu footer_options">{t("pt")}</p></Col>
            <Col xs lg="2">
              <p  onClick={()=>ChangeL("en")} className="footer_titulo textAligncenter hover_underline_menu footer_options">{t("en")}</p></Col>
            <Col xs lg="2">
              <p onClick={()=>ChangeL("fr")} className="footer_titulo textAligncenter hover_underline_menu footer_options">{t("fr")}</p></Col>
            <Col xs lg="2">
              <p onClick={()=>ChangeL("de")} className="footer_titulo textAligncenter hover_underline_menu footer_options">{t("de")}</p></Col>
          </Row>
        </Col>
        </Container>
      </Row>
      
    </>}
    </>
  );
}

export default FooterComponent;
