export const TRANSLATIONS_PT = {
  // Linguas 
  pt: "PORTUGUÊS",
  en: "INGLÊS",
  fr: "FRANCÊS",
  de: "ALEMÃO",
  // Menu 
  pesquisar: "Pesquisar",
  menu_manifesto: "MANIFESTO",
  menu_sobre_nos: "SOBRE NÓS",
  menu_contactos: "CONTACTOS",
  menu_waitlist: "WAITLIST",


  // Pesquisa

  titulo_pesquisa: "Resultados de Pesquisa",

  // Home 
  titulo_home_foto: "Invest Green <br/> Reduce Your Eco Guilt",
  botao_home_sobre_nos: "SOBRE NÓS",
  titulo_home_green_investing: "Green Investing and Us",
  texto_home_green_investing: "Trabalhamos lado a lado com uma das indústrias mais poluentes do Mundo. Por estarmos cientes desta realidade, decidimos que estava na hora de tomar uma posição.",
  texto_home_green_investing_2: "Tirando partido da vertente emocional que é investir em algo que o apaixona e mantendo o foco na racionalidade de poder converter um investimento em dinheiro, a Clock (Re)Cycle nasce com a vontade de selar a promessa de “luxo sustentável”.",


  texto_home_cote: "Porque não sermos o exemplo para a indústria de que ajudar o Meio Ambiente deve ser a nossa missão e não a nossa consequência?",

  texto_home_geracao: "A passagem de peças de geração em geração é do mais característico e belo que o vintage comporta. A possibilidade de trazermos no nosso pulso décadas de histórias, anos de memórias.",
  texto_home_geracao_2: "Na Clock (Re)Cycle queremos honrar e preservar a alma de cada peça como algo único e intemporal, recordando-o que o investimento em vintage é também um investimento na continuidade de recordações.",

  texto_home_knowhow: "Através da expertise e Know-how de mais de 40 anos na área da equipa por detrás deste projeto, garantimos que o nosso investimento é seguro e fidedigno, transmitindo-lhe uma posição de confiança e estabilidade. Na Clock (Re)Cycle dedicamo-nos diariamente à procura de peças únicas para si, mas o vintage é algo extremamente pessoal, por isso convidamo-lo a partilhar connosco as suas peças de eleição, para que possamos cada vez mais, ajustar-nos às suas preferências.",

  botao_home_waitlist: "WAITLIST",
  titulo_home_paralax: "Clock Tree Circle ",
  titulo_home_vanguarda: "Na vanguarda da mudança",
  texto_home_vanguarda: "A Clock (Re)Cycle baseia a sua atuação no mais circular e sustentável que pode existir: o mercado vintage.",
  texto_home_vanguarda_2: "No entanto, queremos mais. Queremos incentivar os nossos investidores a fazerem verdadeiramente parte da mudança, mas também queremos desafiarmo-nos diariamente como empresa, a tornar a nossa atuação cada vez mais sustentável.",
  texto_home_vanguarda_3: "Assim criamos a iniciativa “Clock Tree Circle”, onde nos comprometemos a plantar 1 árvore por cada investimento, reduzindo a pegada ecológica que o transporte das nossas peças implica.",


  // MANIFESTO 
  botao_manifesto_botao: "MANIFESTO",
  texto_manifesto_cote: "Hoje em dia, todos experienciamos o <i>Eco Guilt Investing/ Buying</i> – quando decidimos investir/ comprar algo sustentável para minorar o nosso sentimento de culpa por comportamentos menos sustentáveis ",
  texto_manifesto_core: "O core da Clock (Re)cycle, como o próprio nome indica, é baseado na nossa iniciativa como empresa de integrar os processos de redução, reciclagem e reutilização em toda a nossa atuação e processos. Lutamos pela reciclagem e reutilização de matérias primas de forma quase infinita, incentivamos a reintrodução no mercado das peças evitando a necessidade de produção em massa e de um novo ciclo de poluição e apoiamos o aumento da circularidade de escala.<p> A longevidade da sustentabilidade revela-se uma realidade.</p>",
  texto_manifesto_clocktreecircle: "Criámos a iniciativa ,<span style='color:#004225;font-family: \"Work Sans Medium\"'>Clock Tree Circle</span> com o objetivo de nos superarmos ao nível da sustentabilidade e circularidade, como uma oportunidade de tornarmos a nossa atuação ainda mais “verde” , reduzindo o impacto ambiental negativo inerente ao transporte das nossas peças, e contribuindo para um aumento da circularidade de escala. De cada vez que fizer um investimento connosco, iremos plantar 1 árvore autóctone.<p>Estimamos que em 2031 já teremos plantado mais de 3200 árvores convidamo-lo a fazer parte desta nova comunidade sustentável e a ter um verdadeiro impacto na luta pela proteção ambiental.</p>",


  // SOBRE NOS 
  titulo_sobrenos_titulo: "Quem somos",
  texto_sobrenos_clock: "Na Clock (Re)Cycle contribuímos diariamente para a redução da pegada ecológica e para o aumento da circularidade de escala, incentivando o green investing e reduzindo o Eco Guilty Living, através da nossa atuação na indústria relojoeira vintage.",
  texto_sobrenos_clock_2: "A nossa premissa é incentivar e permitir aos nossos clientes a possibilidade de associarem, a sua paixão por relojoaria à consciência ecológica.",
  botao_sobrenos_manifesto: "MANIFESTO",
  titulo_sobrenos_core: "O nosso core é intrinsecamente verde.",
  texto_sobrenos_core: "Trabalhamos todos os dias para alicerçar a possibilidade de lhe oferecer um investimento seguro, capaz de gerar um retorno financeiro maior que o seu investimento inicial, com a hipótese de contribuir direta e essencialmente para o abaixamento e compensação da pegada ecológica da indústria extratora, protegendo a natureza e impedindo a prática de “abandonar sem reciclar”.",
  texto_sobrenos_equipa: "A nossa equipa tem mais de 40 anos de experiência no mercado de compra, comercialização e avaliação de peças de relojoaria de luxo vintage. O seu vasto conhecimento e à vontade na área, fazem com que seja a equipa ideal para liderar este projeto, concedendo confiança e credibilidade em todos os passos do processo.",
  titulo_sobrenos_valores: "Os nossos valores",
  texto_sobrenos_valores_ecologica: "Consciência ecológica",
  texto_sobrenos_valores_heranca: "Herança",
  texto_sobrenos_valores_credibilidade: "Credibilidade",
  texto_sobrenos_cote: "A possibilidade de investir em peças únicas e com alma, que nos transportam para uma nova dimensão de consciencialização ambiental com um quê de exclusividade que apenas o vintage comporta.",

  // Contactos 

  titulo_contactos_titulo: "Contacte-nos",
  texto_contactos_texto: "Na Clock (Re)cycle queremos garantir que todas as suas dúvidas são tratadas da forma mais rápida e eficaz possível.",
  texto_contactos_texto_1: "<p>Para isso, a nossa equipa de Costumer Service está ao seu dispor para esclarecer todas as questões que possam surgir.</p> Não hesite em contactar-nos:</br></br> 9h – 18h (GMT)",
  texto_contactos_texto_2: "Estamos à distância de 1 clique!",


  // WaitList 

  titulo_waitlist: "Waitlist",
  texto_waitlist: "Não encontra o que procura? Nós procuramos por si.",
  texto_waitlist_2: "Na Clock (Re)cycle queremos ajudá-lo a encontrar as suas peças preferidas, por isso criámos a Waitlist. Aqui poderá descrever as peças que gostaria de ver no nosso website e faremos o nosso melhor para atender ao seu pedido.",
  texto_waitlist_3: "Let the search begin!",
  texto_waitlist_nome: "Nome",
  texto_waitlist_descricao: "Descrição",
  texto_waitlist_email: "E-mail",
  texto_waitlist_upload: "Upload Fotografia",
  botao_waitlist_upload: "ESCOLHER FICHEIRO",
  botao_waitlist_enviar: "ENVIAR",


  // Legal 



  texto_legal_1: "Disposições Legais",
  texto_legal_2: "Termos e Condições",
  texto_legal_3: "Termos",
  texto_legal_4: "<p>A Clock (Re)Cycle, é a proprietária deste site e, salvo outra indicação, de todos os conteúdos nele incluídos.</p><p>Ao aceder e utilizar este site, concorda com os termos e condições definidos a seguir. De notar que a Clock (Re)Cycle pode em qualquer altura e à sua discrição alterar os termos e condições recomendando, por esse motivo, que os reveja regularmente.</p><p>Se qualquer um destes termos for considerado ilegal, inválido ou sem efeito, a validade dos restantes termos não será afetada ou diminuída.</p>",
  texto_legal_5: "Utilização",
  texto_legal_6: "<p>Os conteúdos deste site só podem ser utilizados para informação pessoal.</p><p>Isto significa que pode visualizar, copiar, imprimir e distribuir qualquer documento aqui publicado pela Clock (Re)Cycle, desde que não com o propósito de comercializar os conteúdos, e desde que seja feita referência à Clock (Re)Cycle no processo de distribuição da informação e que todas as mensagens de copyright e outras menções a direitos de autor dos conteúdos sejam mantidas.</p>",
  texto_legal_5: "Copyright",
  texto_legal_7: "<p>Todas as imagens, o logótipo da empresa, o nome da empresa, os textos e o software incluídos neste site são, salvo quando indicado o contrário, propriedade da Clock (Re)Cycle e encontram-se protegidas pelas leis da Propriedade Industrial.</p><p>Toda e qualquer utilização do logótipo, nome, textos, imagens e software é proibida, exceto nas condições expressas no site.</p><p>A utilização ou distribuição não autorizada de qualquer material deste site pode ser objeto de sanções civis ou criminais de acordo com as leis aplicáveis.</p>",
  texto_legal_8: "Política de Informação",
  texto_legal_9: "<p>A Clock (Re)Cycle não se compromete a atualizar a informação ou materiais constantes do site. As alterações à informação podem ser feitas em qualquer altura sem notificação prévia.</p><p>A Clock (Re)Cycle não será em situação alguma responsável por quaisquer perdas, diretas ou indiretas, derivadas da utilização ou impossibilidade de utilização deste site ou do seu conteúdo.</p>",
  texto_legal_10: "Política de Cookies",
  texto_legal_11: "O que são Cookies?",
  texto_legal_12: "<p>Cookies são ficheiros que ficam gravados no seu computador e que têm como único objetivo simplificar a sua navegação no website (estes cookies não podem conter vírus nem ser executados, pois não são ficheiros ativos).</p>",
  texto_legal_13: "<p>Os cookies permitem:</p>",
  texto_legal_14: "<li>Melhorar a experiência de navegação e usufruir dos nossos conselhos personalizados;</li><li>Estar informado sobre as nossas ofertas promocionais em diversos produtos;</li><li>Guardar os produtos no seu carrinho de compras para poder continuar a encomenda a qualquer momento;</li><li>Estes cookies apenas podem ser lidos pela Clock (Re)Cycle e pelo utilizador, que poderá apagá-los se desejar.</li>",
  texto_legal_15: "Cookies utilizadas:",
  texto_legal_16: "<p>Cookies Essenciais – Estes cookies permitem funcionalidades essenciais, tais como segurança, verificação de identidade e gestão de rede. Esses cookies não podem ser desativados.</p>",
  texto_legal_17: "<p>Cookies de Marketing – Estes cookies são usados para procurar a eficácia da publicidade, fornecer um serviço mais relevante e anúncios melhores para atender aos seus interesses.</p>",
  texto_legal_18: "<p>Cookies Analíticos – Estes cookies ajudam-nos a entender como os visitantes interagem com o nosso site, descobrir erros e fornecer uma melhor análise geral.</p>",
  texto_legal_19: "Outros sites",
  texto_legal_20: "<p>Este site contém/poderá vir a conter ligações para sites de terceiros. No entanto, estas ligações são disponibilizadas para conveniência dos nossos clientes e a Clock (Re)Cycle não assume qualquer responsabilidade pelos conteúdos, exatidão da informação ou políticas de privacidade desses mesmos sites.</p>",
  texto_legal_21: "Contacto",
  texto_legal_22: "Se tiver alguma questão relativa a estes termos e condições ou em particular, à política de privacidade ou exata localização das peças, não hesite em contactar-nos através do email geral@clockrecycle.com .",
  // Politica de Privacidade 

  texto_politica_1: "Política de Privacidade ",
  texto_politica_2: "Confidencialidade",
  texto_politica_3: `<p>A Empresa Clock (Re)Cycle, garante a confidencialidade e a proteção de todos os dados pessoais recolhidos, nos termos do RGPD (Regulamento Geral de Proteção de Dados), e em rigoroso respeito pelos direitos e garantias consagradas na Constituição e na lei.</p>
<p>Informação e consentimento para o tratamento de dados</p><p>A recolha dos elementos necessários à validação jurídica e fiscal de contratos ou documentos de transação, bem como a elaboração de ficha de cliente que permita cumprir a rastreabilidade subjacente a eventual relação de negócio, no cumprimento de obrigações legais, corresponde a um ato de recolha de dados pessoais, que serão depois objeto de organização, conservação, consulta e utilização, pelo que está sujeito à aplicação do Regulamento (UE) 2016/679 do Parlamento Europeu e do Conselho de 27 de Abril de 2016 relativo à proteção das pessoas singulares no que diz respeito ao tratamento de dados pessoais e à livre circulação desses dados.</p>
<p>Para que o tratamento dos dados pessoais seja lícito é solicitado ao titular dos dados pessoais que leia as seguintes informações e que, no final, confirme a informação recebida e/ou dê o seu consentimento para o tratamento.</p>
<p>Identificação do responsável pelo tratamento</p>
<p>Empresa: Clock (Re)Cycle</p>
<p>Nº de Matrícula/NIPC: 516609459</p>
<p>Sede: Rua de Cedofeita 348, 4050-174, Porto</p>
<p>Contacto telefónico: 223220908</p>
<p>E-mail: geral@clockrecycle.com</p>
`,
  texto_politica_4: "Finalidade do Tratamento",
  texto_politica_5: `<p>A finalidade do tratamento dos dados pessoais é validar jurídica e fiscalmente os contratos e documentos a eles subjacentes, como sejam os que se refiram a pagamentos e transações, bem como a criação de uma ficha individual de cliente/fornecedor, para cumprimento de obrigações legais de conservação, comunicação, exame e supervisão nas relações de negócios. Os dados de contacto servirão para cumprir qualquer dever de informação adicional ou retificação necessária, no âmbito restrito dos contratos subjacentes.</p>
<p>Em caso de consentimento explícito do seu titular, os dados de contacto poderão ser utilizados para solicitar consentimento adicional de tratamento dos dados, bem como remeter informação ou convites para eventos ou campanhas no âmbito da atividade comercial da empresa.</p>
<p>Obrigatoriedade do fornecimento dos dados</p>
<p>O fornecimento dos dados pessoais à Empresa Clock (Re)Cycle é condição essencial para a realização das transações, sempre que a legislação obrigue à identificação dos fornecedores/clientes particulares.</p>`,
  texto_politica_6: "Categoria dos dados pessoais recolhidos",
  texto_politica_7: "Os dados pessoais recolhidos são (i) os dados de identificação (como: documento de identificação, nome completo, residência , n.º de contribuinte); (ii) os dados de contacto (como: o n.º de telefone e o e-mail); (iii) todos os elementos recolhidos na cópia do cartão de cidadão ou na recolha por meio de “chip” ou outro documento de identificação considerado válido pela lei para as transações comerciais ao abrigo da Lei RJOC e LBCFT.",
  texto_politica_8: "Destinatários ou Categorias de Destinatários dos Dados Pessoais",
  texto_politica_9: `<p>Os dados recolhidos servirão única e exclusivamente para a finalidade supra descrita, e serão tratados no estrito cumprimento dos deveres previsto no RGPD e demais legislação. Quando legalmente obrigatória, a comunicação dos dados pessoais às autoridades judiciais e outras entidades previstas na legislação, far-se-á pelos meios por elas disponibilizados.</p>
<p>Para o tratamento administrativo dos documentos onde constam dados pessoais, o responsável pela proteção de dados assume diretamente os procedimentos, ou delega em terceiro hierarquicamente subordinado, sob sua supervisão direta, contratualizando o dever de confidencialidade e proteção.</p>
<p>No caso de externalização dos serviços que efetuem tratamento dos dados pessoais sob sua responsabilidade e proteção, será contratualizado o dever de confidencialidade e proteção, verificada a conformidade com o RGPD, bem como das condições físicas e procedimentais de tratamento e proteção dos dados.</p>`,
  texto_politica_10: `Prazo de Conservação dos Dados Pessoais`,
  texto_politica_11: `<p>Os dados pessoais serão conservados pelos prazos inerentes ao cumprimento das obrigações legais, no caso dos documentos subjacentes a contratos ou transações comerciais e à ficha de cliente, pelo prazo considerado legal pela legislação portuguesa para o(s) ato(s) que estejam na origem da sua recolha e conservação, no caso de qualquer outro tratamento de dados sob consentimento expresso pelo seu titular.</p>`,
  texto_politica_12: `Direitos do titular dos dados pessoais`,
  texto_politica_13: `<p>direito de solicitar ao responsável pelo tratamento acesso aos dados pessoais que lhe digam respeito;</p>
<li>direito à sua retificação;</li>
<li>direito ao seu apagamento;</li>
<li>direito à limitação do tratamento no que disser respeito ao titular dos dados;</li>
<li>direito de se opor ao tratamento;</li>
<li>direito à portabilidade dos dados;</li>
<li>direito de retirar consentimento ao tratamento de dados em qualquer altura, com o consequente apagamento dos dados pessoais;</li>
<li>direito de apresentar reclamações à CNPD.</li>
`,
  texto_politica_14: "Direito de Acesso ao Titular dos Dados",

  texto_politica_15: `<p>O direito de solicitar o acesso aos dados pessoais traduz-se no direito de obter do responsável pelo tratamento a confirmação de que os dados pessoais que lhe digam respeito são ou não objeto de tratamento e, se for esse o caso, o direito de aceder aos seus dados pessoais e às informações constantes do artigo 15.º do Regulamento (UE) 2016/679 do Parlamento Europeu e do Conselho de 27 de Abril de 2016.</p>
<p>O direito de acesso compreende ainda o direito a obter uma cópia dos dados pessoais, que será fornecida num formato eletrónico de uso corrente, quando o pedido for realizado por meios eletrónicos. Caso o pedido seja feito por correio tradicional, salvo pedido em contrário, a cópia dos dados pessoais será fornecida da mesma forma.</p>
<p>No caso de outros pedidos de cópias dos dados pessoais, poderá ser cobrada o valor pecuniário, para cobertura dos custos administrativos.</p>
<p>Caso o direito a obter uma cópia dos dados pessoais possa prejudicar ou pôr em causa direitos e liberdades de terceiros, a cópia não incluirá esses dados.</p>`,
  texto_politica_16: "Direito de Retificação",
  texto_politica_17: `<p>O titular tem o direito de obter, sem demora injustificada, do responsável pelo tratamento a retificação dos dados pessoais inexatos que lhe digam respeito. Tendo em conta as finalidades do tratamento, o titular dos dados tem direito a que os seus dados pessoais incompletos sejam completados, incluindo por meio de uma declaração adicional.</p>
<p>Direito ao Apagamento dos Dados («Direito a ser esquecido»)</p>
<p>O titular tem o direito de obter do responsável pelo tratamento o apagamento dos seus dados pessoais, sem demora injustificada, e este tem a obrigação de apagar os dados pessoais, sem demora injustificada, quando se aplique algum dos motivos constantes do artigo 17.º do Regulamento (UE) 2016/679 do Parlamento Europeu e do Conselho de 27 de Abril de 2016, nomeadamente, quando o titular dos dados retira o consentimento em que se baseia o tratamento dos dados e se não existir outro fundamento jurídico para o referido tratamento.</p>`,
  texto_politica_18: `Forma de exercício dos direitos`,
  texto_politica_19: `O exercício dos direitos do titular dos dados pessoais poderá realizar-se de forma eletrónica através da área reservada na página https://www.clockrecycle.com/, por telefone, desde que comprovada a identidade do titular ou através de pedido escrito com assinatura reconhecida dirigido para as instalações da Clock (Re)Cycle.`,
  texto_politica_20: `Declaração do Titular dos Dados`,
  texto_politica_21: `
<p>Tomei conhecimento de todos os procedimentos, obrigações e direitos supracitados, inerentes à recolha e tratamento dos dados pessoais.</p>
<p>Expresso o meu consentimento informado para o tratamento dos meus dados pessoais, nos termos do texto anterior, bem como nos termos a seguir aditados:</p>
<p>Os meus dados de contacto poderão ser utilizados para solicitar consentimento adicional de tratamento dos dados, bem como remeter informação ou convites para eventos ou campanhas no âmbito da atividade comercial da empresa Clock (Re)Cycle.</p>
<p>Os meus dados pessoais serão conservados pelo prazo legal obrigatório para o cumprimento da legislação portuguesa, nomeadamente as Leis; RJOC, LCBFT e prazos da AT e até 180 dias após findarem os prazos ora referidos após o último contacto estabelecido entre as partes, estando garantidos todos os direitos que me assistem enquanto titular referidos dados, nos termos do RGPD e demais legislação que os proteja. Findo este prazo, os dados serão destruídos de forma segura e permanente, tanto nos suportes originais como em todas as eventuais cópias então existentes.</p>
<p>O responsável pelo tratamento e guarda dos dados:</p>
<p>Clock (Re)Cycle</p>`,
  texto_politica_22: `<p>Lei de Branqueamento de Capitais e Financiamento ao Terrorismo </p> Ao abrigo da Lei BCFT 83/2017 de 18 Agosto, os limites de:`,
  texto_politica_23: `<p>Pagamento em numerário até 999,99€.</p>
<p>Recebimento em numerário:</p>
<p>999,99€ – Empresa (i)</p>
<p>2.999,99€ – Particulares. Acima destes valores só são aceites meios bancários devendo fornecer nome e morada.</p>
<p>9.999,99€ – Particulares sem residência fiscal em Portugal. Devem provar residência fiscal fora de Portugal.</p>
<p>Acima de 15.000,00€ - Todos os clientes são identificados através do Anexo I da ASAE ou Anexo Empresas, independentemente do meio de pagamento.</p>
<p>(i) Sempre que desejarem fatura com nome e contribuinte empresarial (Pessoa Coletiva) devem provar que estão autorizados por essa empresa a efetuarem aquisições em nome da mesma, independentemente do valor.</p>
`,

  // Livro Reclamacoes

  texto_reclamacao_1: `Resolução de Conflitos Online`,
  texto_reclamacao_2: `<p>Caro cliente,</p>
<p>A União Europeia criou um website para apoiar todos os consumidores a apresentarem as suas reclamações sobre qualquer litígio em que estejam envolvidos. Neste sentido, a Clock (Re)Cycle disponibiliza toda a informação para que possa exercer o seu direito de reclamação junto de uma entidade oficial, terceira e imparcial ao processo, («entidade de resolução de litígios») que o ajudará a resolver o litígio em questão.</p>
<p>Assim, se ficou insatisfeito com a aquisição de um bem ou serviço no nosso website, ou com a solução por nós apresentada para resolver a situação, pode aceder a este site oficial https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=PT e expor a sua reclamação.</p>`,
  texto_reclamacao_3: `O que é a resolução alternativa de litígios?`,
  texto_reclamacao_4: `
<p>A resolução alternativa de litígios é a possibilidade que todos os consumidores têm ao seu dispor de recorrer a entidades oficiais que os ajudem na resolução, ou orientação de um conflito, antes de abrirem processos litigiosos nos Tribunais.
Normalmente, o procedimento é o seguinte: o cliente pede a um terceiro imparcial que intervenha como mediador entre si e o comerciante que é o alvo da sua reclamação. O mediador pode sugerir uma solução para a sua reclamação, ou reunir as partes para encontrar uma solução.
A resolução alternativa de litígios é, normalmente, menos dispendiosa, menos formal e mais rápida do que a via judicial.
A Clock (Re)Cycle disponibiliza os websites de algumas entidades que poderão ajudá-lo a apresentar os seus argumentos:
</p>
<p>CACCL – Centro de Arbitragem de Conflitos de Consumo do Porto http://www.centroarbitragemlisboa.pt/ </p>
<p>CICAP – Centro de Arbitragem de Conflitos de Consumo https://www.cicap.pt/</p>`,
  texto_reclamacao_5: `Livro de Reclamações`,
  texto_reclamacao_6: `Pode aceder ao livro de reclamações online através do link disponibilizado em baixo.`,
  texto_reclamacao_7: `<a href="https://www.livroreclamacoes.pt/Inicio/">https://www.livroreclamacoes.pt/Inicio/</a>`,






  // Footer


  footer_newsletter: "Junte-se à nossa Newsletter:",
  footer_email: "Email*",
  footer_subscrever: "SUBSCREVER",
  footer_manifesto: "MANIFESTO",
  footer_sobre_nos: "SOBRE NÓS",
  footer_contactos: "CONTACTOS",
  footer_apoio_cliente: "APOIO AO CLIENTE",
  footer_politicas: "Políticas de Privacidade",
  footer_legais: "Disposições legais",
  footer_reclamacoes: "Livro de Reclamações"

}