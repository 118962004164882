
import React, { useState } from 'react';
import NavbarComponent from './../Components/Navbar';
import FooterComponent from './../Components/Footer';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { ScrollToTop } from "react-scroll-up";

import {
  ScrollingProvider,
  useScrollSection,
  Section,
} from 'react-scroll-section';
import cote from './../Assets/cote.svg';
import watch from './../Assets/watch.svg';
import { Translation } from 'react-i18next';

import i18n from 'i18next';


i18n.changeLanguage(localStorage.getItem("language"));
export default class Home extends React.Component {





  async componentDidMount() {

  }



  scrollToTestDiv = value => {
    const divElement = document.getElementById('sobrenos');
    divElement.scrollIntoView({ behavior: 'smooth' });
  }


  ScrollMobile = value => {
    var element = document.getElementById('sobrenos');
    var headerOffset = 120;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }


  render() {


    return (<div>      {isMobile && window.innerWidth < 720 ? <>
      <Container className="navbar_fixed">
        <NavbarComponent page={"Home"}></NavbarComponent>
      </Container>
      <Container style={{ paddingRight: "16px", paddingLeft: "16px", paddingTop: "120px" }}>


        <Row className="justify-content-md-center">
          <Col xs lg="10">
            <Row className="mb-47">
              <Col xs lg="12" style={{ position: "relative" }} >
                <img src="/Assets/mobile/ftg_homepage 9.jpg" style={{ width: '100%', maxHeight: "511px" }}></img>
                <div className="homepage_txt_mobile"><div dangerouslySetInnerHTML={{ __html: (i18n.t("titulo_home_foto")) }}></div> </div>
              </Col>
            </Row>
            <Row>
              <Col xs lg={{ span: 6, offset: 3 }} className="mb-80 textAligncenter">
                <Button onClick={this.ScrollMobile}>{i18n.t("botao_home_sobre_nos")}</Button>
              </Col>
            </Row>
            <Section id="sobrenos">
              <Row className="justify-content-md-center">

                <Col xs lg="10" className="mb-24">
                  <p className="IMB_Title"> {i18n.t("titulo_home_green_investing")}</p>
                </Col>
              </Row>
              <Row className="justify-content-md-center mb-80" >

                <Col xs lg="10">
                  <p className="WORK_Description">{i18n.t("texto_home_green_investing")}</p>
                  <p>{i18n.t("texto_home_green_investing_2")}</p>

                </Col>
              </Row>
              <Row>
                <Col xs lg="12" className="mb-40">
                  <img src="/Assets/mobile/ftg_homepage02 2.jpg" style={{ width: '100%' }}></img>
                </Col>
              </Row>
              <Row>
                <Col xs lg="12" className="mb-80" >
                  <img src="/Assets/mobile/mobile_clock_home.jpg" style={{ width: '100%' }}></img>
                </Col>
              </Row>
              <Row className="justify-content-md-center">
                <Col xs lg="1" className="textAligncenter mb-33">
                  <img src={cote}></img>
                </Col>
              </Row>
              <Row className="justify-content-md-center mb-33">
                <Col xs lg="8" >
                  <p className="IMB_Title_green">{i18n.t("texto_home_cote")}</p>
                </Col>
              </Row>
              <Row className="justify-content-md-center  mb-64">
                <Col xs lg="1" className="textAligncenter">
                  <img src={cote} style={{ transform: "scaleX(-1)" }}></img>
                </Col>
              </Row>
              <Row className="justify-content-md-center mb-80">
                <Col xs lg="12">
                  <img src="/Assets/mobile/ftg_homepage10 2.jpg" style={{ width: '100%' }}></img>
                </Col>
              </Row>
              <Row className="justify-content-md-center mb-80">
                <Col xs lg="10">
                  <p>{i18n.t("texto_home_geracao")}</p><p>
                    {i18n.t("texto_home_geracao_2")}</p>

                </Col>
              </Row>
              <Row className="justify-content-md-center">

                <Col xs lg="12" className="mb-40">
                  <img src="/Assets/mobile/clockrec_mobile.jpg" style={{ width: '100%' }}></img>
                </Col>
              </Row>
              <Row>
                <Col xs lg="12" className="mb-80">
                  <img src="/Assets/mobile/ftg_homepage09 2.jpg" style={{ width: '100%' }}></img>
                </Col>
              </Row>
              <Row className="justify-content-md-center mb-40">
                <Col xs lg="10" >
                  <p className="WORK_Description"><div dangerouslySetInnerHTML={{ __html: (i18n.t("texto_home_knowhow")) }}></div></p>

                </Col>
              </Row>
              <Row>
                <Col xs lg={{ span: 4, offset: 4 }} className="mb-121 textAligncenter">
                  <a href="/waitlist" className={"btn btn-primary"}>{i18n.t("botao_home_waitlist")}</a>
                </Col>
              </Row>
              <Row className="mb-40">
                <Col xs lg="12" className={"parallax_image_mobile"}>
                  <p className="titulo_paralax_mobile"> {i18n.t("titulo_home_paralax")}</p>
                </Col>
              </Row>
             
              <Row className="justify-content-md-center">
                <Col xs lg="10" lassName="mb-20">
                  <p className="IMB_Title"> {i18n.t("titulo_home_vanguarda")}</p>
                </Col>
              </Row>
              <Row className="justify-content-md-center mb-80">
                <Col xs lg="10" >

                  <p className="WORK_Description"> {i18n.t("texto_home_vanguarda")}
                    {i18n.t("texto_home_vanguarda_2")}
                    {i18n.t("texto_home_vanguarda_3")}</p>

                </Col>
              </Row>
              <Row className="justify-content-md-center mb-80">
                <Col xs lg="10" className="textAligncenter" >

                  <a href="#"  ><img src="/Assets/btn_up.svg" style={{ width: "50px" }}></img></a>
                </Col>
              </Row>
            </Section>
          </Col>
        </Row>
      </Container>

      <Container className="mt-3">
        <FooterComponent></FooterComponent>

      </Container>
    </> : <>
      {/* DESKTOP */}
      <Container className="mt-3">
        <NavbarComponent page={"Home"}></NavbarComponent>

      </Container>
      <Container>

        <Row className="mb-47">
          <Col xs lg="12" style={{ padding: "0px" }}>
            <img src="/Assets/ftg_homepage 1.jpg" style={{ width: '100%' }}></img>
            <div className="homepage_txt"><div dangerouslySetInnerHTML={{ __html: (i18n.t("titulo_home_foto")) }}></div> </div>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col xs lg="10">
            <Row>
              <Col xs lg={{ span: 4, offset: 4 }} className="mb-121 textAligncenter">
                <Button onClick={this.scrollToTestDiv}>{i18n.t("botao_home_sobre_nos")}</Button>
              </Col>
            </Row>
            <Section id="sobrenos">
              <Row className="justify-content-md-center">

                <Col xs lg="10" className="mb-24">
                  <p className="IMB_Title"> {i18n.t("titulo_home_green_investing")}</p>
                </Col>
              </Row>
              <Row className="justify-content-md-center mb-80" >

                <Col xs lg="10">
                  <p className="WORK_Description">{i18n.t("texto_home_green_investing")}</p>
                  <p>{i18n.t("texto_home_green_investing_2")}</p>

                </Col>
              </Row>
              <Row className="justify-content-md-center mb-64">

                <Col xs lg="4">
                  <img src="/Assets/ftg_homepage02 1.jpg" style={{ width: '100%', maxHeight: '527px' }}></img>
                </Col>
                <Col xs lg="8">
                  <img src="/Assets/ftg_homepage06 1.jpg" style={{ width: '100%' }}></img>
                </Col>
              </Row>
              <Row className="justify-content-md-center">
                <Col xs lg="1" className="textAligncenter mb-33">
                  <img src={cote}></img>
                </Col>
              </Row>
              <Row className="justify-content-md-center">
                <Col xs lg="8">
                  <p className="IMB_Title_green">{i18n.t("texto_home_cote")}</p>
                </Col>
              </Row>
              <Row className="justify-content-md-center  mb-64">
                <Col xs lg="1" className="textAligncenter">
                  <img src={cote} style={{ transform: "scaleX(-1)" }}></img>
                </Col>
              </Row>
              <Row className="justify-content-md-center mb-80">
                <Col xs lg="12">
                  <img src="/Assets/ftg_homepage10 1.jpg" style={{ width: '100%' }}></img>
                </Col>
              </Row>
              <Row className="justify-content-md-center mb-80">
                <Col xs lg="10">
                  <p style={{ marginBottom: "0px" }}>{i18n.t("texto_home_geracao")}</p><p>
                    {i18n.t("texto_home_geracao_2")}</p>

                </Col>
              </Row>
              <Row className="justify-content-md-center mb-80">

                <Col xs lg="8">
                  <img src="/Assets/clockrec_homepage.jpg" style={{ width: '100%' }}></img>
                </Col>
                <Col xs lg="4">
                  <img src="/Assets/ftg_homepage09 1.jpg" style={{ width: '100%', maxHeight: '527px' }}></img>
                </Col>
              </Row>
              <Row className="justify-content-md-center mb-40">
                <Col xs lg="10" >
                  <p className="WORK_Description"><div dangerouslySetInnerHTML={{ __html: (i18n.t("texto_home_knowhow")) }}></div></p>

                </Col>
              </Row>
              <Row>
                <Col xs lg={{ span: 4, offset: 4 }} className="mb-121 textAligncenter">
                  <a href="/waitlist" className={"btn btn-primary"} >{i18n.t("botao_home_waitlist")}</a>
                </Col>
              </Row>
              <Row className="mb-80">
                <Col xs lg="12" className={"parallax_image"}>
                  <p className="titulo_paralax"> {i18n.t("titulo_home_paralax")}</p>
                </Col>
              </Row>

              <Row className="justify-content-md-center">
                <Col xs lg="10">
                  <p className="IMB_Title"> {i18n.t("titulo_home_vanguarda")}</p>
                </Col>
              </Row>
              <Row className="justify-content-md-center mb-80">
                <Col xs lg="10" >

                  <p className="WORK_Description">{i18n.t("texto_home_vanguarda")}<br />
                    {i18n.t("texto_home_vanguarda_2")}<br />
                    {i18n.t("texto_home_vanguarda_3")}</p>

                </Col>
              </Row>
              <Row className="justify-content-md-center mb-121">
                <Col xs lg="10" className="textAligncenter" >
                  <a href="#"  ><img src="/Assets/btn_up.svg" style={{ width: "50px" }}></img></a>
                </Col>
              </Row>
            </Section>
          </Col>
        </Row>

      </Container>
      <div className="FooterAll">
        <FooterComponent></FooterComponent>

      </div>

    </>}

    </div>
    );
  }
}


