
import React, { useState,Fragment } from 'react';
import NavbarComponent from '../Components/Navbar';
import FooterComponent from '../Components/Footer';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import {
  ScrollingProvider,
  useScrollSection,
  Section,
} from 'react-scroll-section';
import cote from './../Assets/cote.svg';
import watch from './../Assets/watch.svg';
import credibilidade from './../Assets/SobreNos_Credibilidade 1.svg';
import historia from './../Assets/SobreNos_Historia 1.svg';
import sustentabilidade from './../Assets/SobreNos_Sustentabilidade 1.svg';

import DOMPurify from 'dompurify'
import i18n from 'i18next';
i18n.changeLanguage(localStorage.getItem("language"));
export default class PoliticaPrivacidade extends React.Component {

        
  scrollToTestDiv = value => {
    const divElement = document.getElementById('manifesto');
    divElement.scrollIntoView({ behavior: 'smooth' });
  }


  render() {


    return ( <> { isMobile && window.innerWidth < 720 ? <>
      <Container  className="navbar_fixed">
        <NavbarComponent page={"PoliticaPrivacidade"}></NavbarComponent>
      </Container>
      <Container style={{paddingRight:"16px",paddingLeft:"16px",marginTop:"120px"}}>
        <Row className="justify-content-md-center">
        <Col xs lg="10">     
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="10">
                <div className="IMB_Title">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_politica_1"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="10">
                <div className="IMB_Title_20">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_politica_2"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center  mb-24" >
              <Col xs lg="10">
                <div className="WORK_Description">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_politica_3"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="10">
                <div className="IMB_Title_20">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_politica_4"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="10">
                <div className="WORK_Description">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_politica_5"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="10">
                <div className="IMB_Title_20">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_politica_6"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="10">
                <div className="WORK_Description">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_politica_7"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="10">
                <div className="IMB_Title_20">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_politica_8"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="10">
                <div className="WORK_Description">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_politica_9"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="10">
                <div className="IMB_Title_20">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_politica_10"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center  mb-24" >
              <Col xs lg="10">
                <div className="WORK_Description">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_politica_11"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="10">
                <div className="IMB_Title_20">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_politica_12"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center  mb-24" >
              <Col xs lg="10">
                <div className="WORK_Description">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_politica_13"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="10">
                <div className="IMB_Title_20">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_politica_14"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center" >
              <Col xs lg="10">
                <div className="WORK_Description">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_politica_15"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center" >
              <Col xs lg="10">
                <div className="IMB_Title_20">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_politica_16"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center  mb-24" >
              <Col xs lg="10">
                <div className="WORK_Description">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_politica_17"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="10">
                <div className="IMB_Title_20">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_politica_18"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="10">
                <div className="WORK_Description">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_politica_19"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="10">
                <div className="IMB_Title_20">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_politica_20"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="10">
                <div className="WORK_Description">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_politica_21"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="10">
                <div className="IMB_Title_20">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_politica_22"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-80" >
              <Col xs lg="10">
                <div className="WORK_Description">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_politica_23"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-121">
                <Col xs lg="10" className="textAligncenter" >

                <a href="#"  ><img src="/Assets/btn_up.svg"  style={{width:"50px"}}></img></a>
                </Col>
              </Row>

          </Col>
        </Row>
      </Container>
      <Container className="mt-3">
        <FooterComponent></FooterComponent>

      </Container>
    </> : <>
      {/* DESKTOP */}
      <Container className="mt-3">
        <NavbarComponent page={"PoliticaPrivacidade"}></NavbarComponent>

      </Container>
      <Container>

        <Row className="justify-content-md-center">
        <Col xs lg="10">     
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="10">
                <div className="IMB_Title">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_politica_1"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="10">
                <div className="IMB_Title_20">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_politica_2"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center  mb-24" >
              <Col xs lg="10">
                <div className="WORK_Description">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_politica_3"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="10">
                <div className="IMB_Title_20">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_politica_4"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="10">
                <div className="WORK_Description">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_politica_5"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="10">
                <div className="IMB_Title_20">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_politica_6"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="10">
                <div className="WORK_Description">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_politica_7"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="10">
                <div className="IMB_Title_20">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_politica_8"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="10">
                <div className="WORK_Description">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_politica_9"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="10">
                <div className="IMB_Title_20">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_politica_10"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center  mb-24" >
              <Col xs lg="10">
                <div className="WORK_Description">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_politica_11"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="10">
                <div className="IMB_Title_20">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_politica_12"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center  mb-24" >
              <Col xs lg="10">
                <div className="WORK_Description">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_politica_13"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="10">
                <div className="IMB_Title_20">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_politica_14"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center" >
              <Col xs lg="10">
                <div className="WORK_Description">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_politica_15"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center" >
              <Col xs lg="10">
                <div className="IMB_Title_20">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_politica_16"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center  mb-24" >
              <Col xs lg="10">
                <div className="WORK_Description">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_politica_17"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="10">
                <div className="IMB_Title_20">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_politica_18"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="10">
                <div className="WORK_Description">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_politica_19"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="10">
                <div className="IMB_Title_20">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_politica_20"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="10">
                <div className="WORK_Description">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_politica_21"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-24" >
              <Col xs lg="10">
                <div className="IMB_Title_20">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_politica_22"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-80" >
              <Col xs lg="10">
                <div className="WORK_Description">
                  <p> <div dangerouslySetInnerHTML={{__html: (i18n.t("texto_politica_23"))}}></div></p>
             </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-121">
                <Col xs lg="10" className="textAligncenter" >

                <a href="#"  ><img src="/Assets/btn_up.svg"  style={{width:"50px"}}></img></a>
                </Col>
              </Row>

          </Col>
        </Row>
      </Container>
      <div className="FooterAll">
        <FooterComponent></FooterComponent>

      </div>
    </>}

    </>
    );
  }
}


