import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
 
import { TRANSLATIONS_PT } from "./pt/translations";
import { TRANSLATIONS_EN } from "./en/translations";
import { TRANSLATIONS_DE } from "./de/translations";
import { TRANSLATIONS_FR } from "./fr/translations";
 
i18n
 .use(initReactI18next)
 .init({
   resources: {
     en: {
       translation: TRANSLATIONS_EN
     },
     pt: {
       translation: TRANSLATIONS_PT
     },
     de: {
        translation: TRANSLATIONS_DE
      },
      fr: {
        translation: TRANSLATIONS_FR
      }
   },
   fallbackLng: "pt", 
   
 });

