import React from 'react';
import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";


import Home from './Pages/Home';
import SobreNos from './Pages/SobreNos';
import Manifesto from './Pages/Manifesto';
import Contacto from './Pages/Contacto';
import WhaitList from './Pages/WhaitList';
import DispoLegal from './Pages/DispoLegal';
import Search from './Pages/Search';
import PoliticaPrivacidade from './Pages/PoliticaPrivacidade';
import LivroReclamacao from './Pages/LivroReclamacao';
import reportWebVitals from './reportWebVitals';


// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
// Importing the Bootstrap CSS
import './Styles/bootstrapPersonalized.css';

render(
  <BrowserRouter>
    <Routes>
    <Route exact path="/complaint" element={<LivroReclamacao />}></Route>
    <Route exact path="/privacy" element={<PoliticaPrivacidade />}></Route>
    <Route exact path="/legal" element={<DispoLegal />}></Route>
    <Route exact path="/waitlist" element={<WhaitList />}></Route>
    <Route exact path="/contact" element={<Contacto />}></Route>
    <Route  path="/search/:search" element={<Search />}></Route>
    <Route exact path="/manifest" element={<Manifesto />}></Route>
      <Route exact path="/about" element={<SobreNos />}></Route>
      <Route exact path="/" element={<Home />}></Route>
      
    </Routes>
  </BrowserRouter>,
  document.getElementById("root")
);

reportWebVitals();
